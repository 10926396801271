import React, { Component, Fragment } from "react"
import styled from "styled-components"

import { filterByTextInsideObjects } from "../../../helpers"

type DualListSelected = {
  origin: string | null
  key: string | null
}
interface IDualListState {
  source: object
  target: object
  totalTarget: number | null
  selected: DualListSelected
}

const DualListContainer = styled.div``

const TableContainer = styled.div`
  border: 1px solid #cecccc;
  padding: 1em 0em;
  & > .tableTitle {
    padding: 0em 1em;
    margin: 0px;
    &.selected {
      margin-bottom: 2em;
    }
  }
  & > .field {
    padding: 0.8em 1em;
    margin: 0px;
    &:not(:last-child) {
      margin: 0px;
    }
  }
`

const StickyHeaderContainer = styled.div`
  padding: 0em 1em;
  & > .table {
    border: none;
    th {
      padding: 0px;
      font-size: 0.9em;
      font-weight: bold;
      border-bottom: 1px solid black;
      &:last-child {
        width: 40px;
        text-align: right;
      }
    }
  }
`

const StickyFooterContainer = styled.div`
  padding: 0em 1em;
  & > .table {
    border: none;
    td {
      padding: 0px;
      font-size: 0.9em;
      font-weight: bold;
      border-top: 1px solid black;
      border-bottom: none;
      &:last-child {
        text-align: right;
      }
    }
  }
`

const TableBodyContainer = styled.div`
  height: 240px;
  overflow: scroll;
  & > .table {
    tr {
      cursor: pointer;
      border: none;
      &:hover {
        background-color: #f5f5f5;
      }
      td {
        border: none;
        padding-right: 0px;
        padding-left: 0px;
        font-weight: 100;
        &:first-child {
          padding-left: 1em;
        }
        &:last-child {
          width: 40px;
          padding-right: 1em;
          text-align: right;
        }
      }
      &.active {
        &:hover {
          background-color: #dadada;
        }
      }
    }
  }
`

const ListItem = styled.tr`
  &.active {
    background-color: #dadada;
  }
`

class DualListBox extends Component<any, IDualListState> {
  state = {
    source: {},
    target: {},
    totalTarget: null,
    selected: {
      origin: null,
      key: null
    }
  }

  componentDidMount() {
    const project = this.props.project
    let initialSourceList: any = this.demandsFactory(project.demands)
    let initialTargetList: any = {}

    //if has target values (editing), populate and remove from source
    if (project.unit && project.unit.demands && project.unit.demands.length) {
      initialTargetList = this.demandsFactory(project.unit.demands)

      // remove from source if existing in target
      Object.keys(initialTargetList).forEach((keyName: any) => {
        delete initialSourceList[keyName]
      })
    }

    this.setState({ source: initialSourceList, target: initialTargetList })
    this.props.updateState({ ...initialTargetList })
    this.calcTotalTargetList(initialTargetList)
  }

  demandsFactory(demands: any) {
    let obj: any = {}
    demands.forEach((item: any) => {
      obj[item.id] = {
        value: item.value,
        adjusted: item.adjusted,
        checked: item.checked,
        code: item.course.code,
        name: item.course.name
      }
    })
    return obj
  }

  handleDemandItemClick = (demandKey: any, originName: string) => {
    this.setState({ selected: { origin: originName, key: demandKey } })
  }

  handleMoveToRightCick = () => {
    const source: any = this.state.source

    if (this.state.selected.origin === "source") {
      // Set value of the Target List
      const selectedKey: any = this.state.selected
      const selectedObject: any = source[selectedKey.key]
      const currentTarget: any = {
        ...this.state.target,
        ...{ [selectedKey.key]: selectedObject }
      }

      try {
        // Remove item from source value
        let currentSource: any = this.state.source
        delete currentSource[selectedKey.key]
        this.setState({
          target: currentTarget,
          source: currentSource,
          selected: {
            origin: null,
            key: null
          }
        })
        this.props.updateState({ ...currentTarget })
        this.calcTotalTargetList(currentTarget)
      } catch (error) {
        console.log(error)
      }
    }
  }

  handleMoveToLeftClick = () => {
    console.log("this.state.target", this.state.target)
    const target: any = this.state.target
    if (this.state.selected.origin === "target") {
      // Set value of the Target List
      const selectedKey: any = this.state.selected
      const selectedObject: any = target[selectedKey.key]
      const currentSource: any = {
        ...this.state.source,
        ...{ [selectedKey.key]: selectedObject }
      }

      try {
        // Remove item from target value
        let currentTarget: any = this.state.target
        delete currentTarget[selectedKey.key]
        this.setState({
          target: currentTarget,
          source: currentSource,
          selected: {
            origin: null,
            key: null
          }
        })
        this.props.updateState({ ...currentTarget })
        this.calcTotalTargetList(currentTarget)
      } catch (error) {
        console.log(error)
      }
    }
  }

  calcTotalTargetList = (currentTarget: object) => {
    let total: number = 0
    Object.entries(currentTarget).forEach(([key, value]: any) => (total += value.value))
    this.setState({ totalTarget: total })
  }

  filterList = (event: any) => {
    const sourceList = this.demandsFactory(this.props.project.demands)
    const q = event.target.value.toLowerCase()
    const updatedList = filterByTextInsideObjects(sourceList, q)

    this.setState({ source: updatedList })
  }

  render() {
    const { source, target, selected }: any = this.state
    return (
      <Fragment>
        <div className="columns">
          <div className="column" style={{ paddingBottom: "0px", marginBottom: "0px" }}>
            <DualListContainer>
              <TableContainer>
                <h6 className="tableTitle">Asignaturas disponibles</h6>
                <div className="field">
                  <div className="control">
                    <input
                      style={{ height: "30px", width: "100%", borderRadius: "0px" }}
                      className="input"
                      type="text"
                      placeholder="Filtrar..."
                      onChange={this.filterList}
                    />
                  </div>
                </div>

                <StickyHeaderContainer>
                  <table className="table">
                    <thead>
                      <tr>
                        <th>asignatura</th>
                        <th>demanda</th>
                      </tr>
                    </thead>
                  </table>
                </StickyHeaderContainer>
                <TableBodyContainer>
                  <table className="table">
                    <tbody>
                      {Object.keys(source).map((demandKey: any, index: number) => {
                        return (
                          <ListItem
                            className={selected.key === demandKey ? "active" : ""}
                            onClick={() => this.handleDemandItemClick(demandKey, "source")}
                            key={demandKey}
                          >
                            <td>
                              {source[demandKey].code} - {source[demandKey].name}
                            </td>
                            <td>{source[demandKey].value}</td>
                          </ListItem>
                        )
                      })}
                    </tbody>
                  </table>
                </TableBodyContainer>
              </TableContainer>
            </DualListContainer>
          </div>

          <div className="column is-1" style={{ textAlign: "center", paddingTop: "17%" }}>
            <a
              onClick={() => this.handleMoveToRightCick()}
              style={{ display: "block", fontSize: "1.6em" }}
            >
              {" "}
              <i className="fas fa-arrow-right" />
            </a>
            <a
              onClick={() => this.handleMoveToLeftClick()}
              style={{ display: "block", fontSize: "1.6em" }}
            >
              {" "}
              <i className="fas fa-arrow-left" />{" "}
            </a>
          </div>
          <div className="column">
            <DualListContainer>
              <TableContainer>
                <h6 className="tableTitle selected">Asignaturas seleccionadas</h6>
                <StickyHeaderContainer>
                  <table className="table">
                    <thead>
                      <tr>
                        <th>asignatura</th>
                        <th>demanda</th>
                      </tr>
                    </thead>
                  </table>
                </StickyHeaderContainer>
                <TableBodyContainer>
                  <table className="table">
                    <tbody>
                      {Object.keys(target).map((demandKey: any, index: number) => {
                        return (
                          <ListItem
                            className={selected.key === demandKey ? "active" : ""}
                            onClick={() => this.handleDemandItemClick(demandKey, "target")}
                            key={demandKey}
                          >
                            <td>
                              {target[demandKey].code} - {target[demandKey].name}
                            </td>
                            <td>{target[demandKey].value}</td>
                          </ListItem>
                        )
                      })}
                    </tbody>
                  </table>
                </TableBodyContainer>

                <StickyFooterContainer>
                  <table className="table">
                    <tfoot>
                      <tr>
                        <td>Asignaturas: 2</td>
                        <td>Demanda: {this.state.totalTarget}</td>
                      </tr>
                    </tfoot>
                  </table>
                </StickyFooterContainer>
                {/*
                <div className="field">
                  <div className="control">
                    <span>Demanda total: {this.state.totalTarget}</span>
                  </div>
                </div>
                */}
              </TableContainer>
            </DualListContainer>
          </div>
        </div>
      </Fragment>
    )
  }
}

export default DualListBox
