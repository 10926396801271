import React from "react"
import ReactDOM from "react-dom"
import { BrowserRouter } from "react-router-dom"
import * as serviceWorker from "./serviceWorker"
import * as Sentry from "@sentry/browser"
import ReactGA from "react-ga"
import { ApolloProvider } from "react-apollo"
import App from "./App"
import client from "./apolloClient"

if (Boolean(process.env.REACT_APP_ACTIVE_SENTRY)) {
  Sentry.init({ dsn: process.env.REACT_APP_SENTRY_DSN })
}

if (Boolean(process.env.REACT_APP_ACTIVE_ANALYTICS) && process.env.REACT_APP_ANALYTICS_ID) {
  ReactGA.initialize(process.env.REACT_APP_ANALYTICS_ID || "")
}

ReactDOM.render(
  <ApolloProvider client={client}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </ApolloProvider>,
  document.getElementById("root")
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
