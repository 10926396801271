import React, { Component } from "react"
import { FieldSelect } from "./index"

const filterFactory = (array: object[]) => {
  return array.map((d: any) => ({
    value: d.id,
    label: d.name
  }))
}

export class DepartmentsFilter extends Component<any, any> {
  render() {
    return (
      <FieldSelect
        label="Escuela"
        placeholder="Selecciona una escuela"
        className={this.props.error ? "with___error" : ""}
        options={filterFactory(this.props.project.departments)}
        defaultValue={this.props.context.dashboard.currentDepartmentListSelected}
        onChangeValue={(filterValue: any) => {
          let newProgramList
          newProgramList = this.props.project.programs.filter((item: any) => {
            return item.department.id === filterValue.value
          })
          if (newProgramList.length > 1)
            newProgramList.unshift({ id: "*", name: "Todas las carreras" })
          this.props.context.updateDashboardCurrentData("currentDepartmentListSelected", {
            selectedValue: filterValue,
            newProgramList: newProgramList
          })
        }}
      />
    )
  }
}

export class ProgramsFilter extends Component<any, any> {
  render() {
    return (
      <FieldSelect
        label="Carrera"
        placeholder="Selecciona una carrera"
        className={this.props.error ? "with___error" : ""}
        options={filterFactory(this.props.context.dashboard.currentProgramList)}
        defaultValue={this.props.context.dashboard.currentProgramListSelected}
        onChangeValue={(filterValue: any) => {
          this.props.context.updateDashboardCurrentData("currentProgramListSelected", filterValue)
        }}
      />
    )
  }
}
