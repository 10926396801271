import React, { Component, Fragment } from "react"
import { Query, withApollo, WithApolloClient } from "react-apollo"
import { ForecastContextInterface } from "../../../../../context"
import { LevelView, SubjectView, CrosslistView } from "./index"
import { Loading } from "../../../../../../../common"
import { GET_UNITS, UNITS_SUBSCRIPTION } from "../queries"

export class SubscriptionWrapper extends Component<any, any> {
  render() {
    return this.props.children
  }
}

type UnitsResultPropsWithApollo = WithApolloClient<ForecastContextInterface & any>
export class UnitsResult extends Component<UnitsResultPropsWithApollo, any> {
  state = {
    selectedDepartment: null,
    selectedProgram: null,
    levelView: null,
    subjectView: null,
    crosslistView: null,
    loadingSearch: false
  }

  subscribeToUnits = (projectId: string, subscribeToMore: any) => {
    subscribeToMore({
      document: UNITS_SUBSCRIPTION,
      variables: {
        projectId: projectId
      }
    })
  }

  componentDidMount() {
    this.setState({
      selectedDepartment: this.props.dashboard.currentDepartmentListSelected.value,
      selectedProgram: this.props.dashboard.currentProgramListSelected.value,
      levelView: this.props.dashboard.unitView.levelView,
      subjectView: this.props.dashboard.unitView.subjectView,
      crosslistView: this.props.dashboard.unitView.crosslistView
    })
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    if (prevProps !== this.props) {
      const unitsAreDifferentsToCurrentState =
        this.state.levelView !== this.props.dashboard.unitView.levelView ||
        this.state.subjectView !== this.props.dashboard.unitView.subjectView ||
        this.state.crosslistView !== this.props.dashboard.unitView.crosslistView

      //UPDATE Filters
      if (
        unitsAreDifferentsToCurrentState &&
        (this.state.selectedDepartment !== this.props.dashboard.currentDepartmentListSelected.value,
        this.state.selectedProgram !== this.props.dashboard.currentProgramListSelected.value)
      ) {
        this.setState({
          selectedDepartment: this.props.dashboard.currentDepartmentListSelected.value,
          selectedProgram: this.props.dashboard.currentProgramListSelected.value
        })
      }

      //UPDATE Loading
      if (this.state.loadingSearch !== this.props.dashboard.loadingSearch) {
        this.setState({ loadingSearch: this.props.dashboard.loadingSearch })
      }

      //UPDATE Views
      if (
        this.state.levelView !== this.props.dashboard.unitView.levelView ||
        this.state.subjectView !== this.props.dashboard.unitView.subjectView ||
        this.state.crosslistView !== this.props.dashboard.unitView.crosslistView
      ) {
        this.setState({
          levelView: this.props.dashboard.unitView.levelView,
          subjectView: this.props.dashboard.unitView.subjectView,
          crosslistView: this.props.dashboard.unitView.crosslistView
        })
      }
    }
  }

  displayUnits(selectedTab: any, project: any) {
    return (
      <Fragment>
        <LevelView
          className={selectedTab !== "curriculum" ? "hidden" : ""}
          items={project.dashboard.levelView}
        />
        <SubjectView
          className={selectedTab !== "subjects" ? "hidden" : ""}
          items={project.dashboard.subjectView}
        />
        <CrosslistView
          className={selectedTab !== "crosslists" ? "hidden" : ""}
          items={project.dashboard.crosslistView}
        />
      </Fragment>
    )
  }

  render() {
    const { selectedDepartment, selectedProgram, loadingSearch } = this.state

    if (loadingSearch)
      return (
        <Fragment>
          <br />
          <Loading />
        </Fragment>
      )

    return (
      <Query
        query={GET_UNITS}
        variables={{
          projectId: this.props.projectId,
          filter: {
            departmentId: selectedDepartment,
            programId: selectedProgram === "*" ? null : selectedProgram
          }
        }}
        fetchPolicy="cache-only"
      >
        {({ loading, error, data, subscribeToMore }: any) => {
          if (loading) return <p>Cargando...</p>
          if (error) return <p>Error :(</p>
          if (data && data.project) {
            this.subscribeToUnits(this.props.projectId, subscribeToMore)
            return this.displayUnits(this.props.dashboard.selectedTab, data.project)
          } else {
            return (
              <>
                <br />
                <p>Selecciona un filtro</p>
              </>
            )
          }
        }}
      </Query>
    )
  }
}

export default withApollo(UnitsResult)
