import { InMemoryCache } from "apollo-cache-inmemory"
import { ApolloClient } from "apollo-client"
import { ApolloLink, split } from "apollo-link"
import { HttpLink } from "apollo-link-http"
import { getMainDefinition } from "apollo-utilities"
import { WebSocketLink } from "apollo-link-ws"
import { setContext } from "apollo-link-context"
import { onError } from "apollo-link-error"
import resolversDefaults from "./resolvers"
import { GRAPHQL_URI, GRAPHQL_WS_URI } from "./common/config"
import fakeAuth from "./authClient"

const cache = new InMemoryCache({
  dataIdFromObject: object => {
    return object.id
    // if (object.__typename != null && object.id != null) {
    //   return object.id
    // }
  }
})

const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, path }) =>
      console.log(`[GraphQL error]: Message: ${message}, Path: ${path}`)
    )
  }

  if (networkError) {
    console.log(`[Network error ${operation.operationName}]: ${networkError.message}`)
  }
})

const authLink = setContext((_, { headers }) => {
  const context = {
    headers: {
      ...headers,
      Authorization: `Bearer ${fakeAuth.getUserData().token}`
    }
  }
  return context
})

const httpLink = new HttpLink({ uri: GRAPHQL_URI })

const wsLink = new WebSocketLink({
  uri: `${GRAPHQL_WS_URI}`,
  options: {
    reconnect: true,
    connectionParams: {
      authToken: fakeAuth.getUserData().token
    }
  }
})

const link = split(
  // split based on operation type
  ({ query }) => {
    const definition = getMainDefinition(query)
    return definition.kind === "OperationDefinition" && definition.operation === "subscription"
  },
  wsLink,
  httpLink
)

const client = new ApolloClient({
  cache,
  link: ApolloLink.from([errorLink, authLink, link]),
  resolvers: resolversDefaults.resolvers
})

cache.writeData({
  data: resolversDefaults.defaults
})

export default client
