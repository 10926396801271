import React, { Component, Fragment } from "react"
import styled from "styled-components"
import { groupBy } from "lodash"
import LevelItem from "./LevelItem"
import SubjectItem from "./SubjectItem"
import CrossListItem from "./CrossListItem"
import { ILevel, ISubject, ICrossList } from "../models"
import { filterByTextInsideObjects } from "../../../helpers"

const Message = styled.article`
  background-color: whitesmoke;
  border-radius: 4px;
  font-size: 1rem;
  &.hidden {
    display: none;
  }
`

const MessageHead = styled.div`
  -webkit-align-items: center;
  -webkit-justify-content: space-between;
  align-items: center;
  color: #fff;
  display: -webkit-flex;
  display: flex;
  font-weight: 700;
  justify-content: space-between;
  line-height: 1.25;
  padding: 0.95em 1em;
  position: relative;
  background-color: #4486c3;
`

const MessageBody = styled.div`
  margin-bottom: 30px;
  padding-bottom: 50px;
  border-radius: 0px;
  color: #4a4a4a;
  padding: 1.25em 1.5em;
  background-color: white;
  & > p {
    font-weight: normal;
  }
`

const MessageLevel = styled.div`
  & > hr {
    &:last-child {
      display: none;
    }
  }
`

const FragmentLevels = (props: any) => {
  const groupedLevels = props
  return (
    <MessageLevel>
      {Object.keys(groupedLevels).map((level: string) => (
        <Fragment key={level}>
          <h6>{`${groupedLevels[level][0].curriculum.program.name} - ${level}`}</h6>
          <div className="columns is-multiline">
            {groupedLevels[level].map((item: ILevel) => (
              <LevelItem key={item.unit.id} {...item} />
            ))}
          </div>
          <hr />
        </Fragment>
      ))}
    </MessageLevel>
  )
}

export class LevelView extends Component<{ items: ILevel[] | any; className: string }, {}> {
  render() {
    const levels: ILevel[] | null = this.props.items
    const groupedLevels: any = groupBy(levels, function(level: any) {
      return level.curriculum.name
    })
    return (
      <Message className={this.props.className}>
        {/* <MessageHead>
          <p>Currículos</p>
        </MessageHead> */}
        <MessageBody>
          {groupedLevels && Object.keys(groupedLevels).length ? (
            <FragmentLevels {...groupedLevels} />
          ) : (
            <p>No hay resultados para mostrar</p>
          )}
        </MessageBody>
      </Message>
    )
  }
}

export class SubjectView extends Component<{ items: ISubject[] | any; className: string }, {}> {
  state = {
    source: []
  }

  componentDidMount() {
    this.setState({ source: this.props.items })
  }

  filterList = (event: any) => {
    if (event.target.value === "") this.setState({ source: this.props.items })

    const q: string = event.target.value.toLowerCase()
    let updatedList: any = []

    this.props.items.filter(
      (item: any): any => {
        if (
          item.unit.label.toLowerCase().search(q) !== -1 ||
          item.subject.toLowerCase().search(q) !== -1
        ) {
          updatedList.push(item)
        }
      }
    )

    this.setState({ source: updatedList })
  }

  render() {
    // const subjects: ISubject[] | null = this.props.items
    const { source } = this.state
    return (
      <Message className={this.props.className}>
        {/* <MessageHead>
          <p>Asignaturas transversales</p>
        </MessageHead> */}
        <MessageBody>
          {this.props.items.length > 0 && (
            <div className="columns">
              <div className="column is-4">
                <input
                  style={{
                    height: "30px",
                    borderRadius: "0px",
                    width: "200px"
                  }}
                  className="input"
                  type="text"
                  placeholder="Filtrar..."
                  onChange={this.filterList}
                />
              </div>
            </div>
          )}
          <div style={{ minHeight: "400px" }}>
            {source && source.length > 0 ? (
              <table className="table demand__resume">
                <thead>
                  <tr>
                    <th> Alias </th>
                    <th> Asignatura </th>
                    <th style={{ width: "153px" }}> Progreso </th>
                  </tr>
                </thead>
                <tbody style={{ fontWeight: 400 }}>
                  {source.map((item: any) => (
                    <SubjectItem key={item.unit.id} {...item} />
                  ))}
                </tbody>
              </table>
            ) : (
              <p>No hay resultados para mostrar</p>
            )}
          </div>
        </MessageBody>
      </Message>
    )
  }
}

export class CrosslistView extends Component<{ items: ICrossList[] | any; className: string }, {}> {
  state = {
    source: []
  }

  componentDidMount() {
    this.setState({ source: this.props.items })
  }

  filterList = (event: any) => {
    if (event.target.value === "") this.setState({ source: this.props.items })

    const q: string = event.target.value.toLowerCase()
    let updatedList: any = []

    this.props.items.filter(
      (item: any): any => {
        if (item.unit.label.toLowerCase().search(q) !== -1) {
          updatedList.push(item)
        }
      }
    )

    this.setState({ source: updatedList })
  }

  render() {
    // const crosslists: ICrossList[] | null = this.props.items
    const { source } = this.state
    return (
      <Message className={this.props.className}>
        {/* <MessageHead>
          <p>Listas cruzadas</p>
        </MessageHead> */}
        <MessageBody>
          {this.props.items.length > 0 && (
            <div className="columns">
              <div className="column is-4">
                <input
                  style={{
                    height: "30px",
                    borderRadius: "0px",
                    width: "200px"
                  }}
                  className="input"
                  type="text"
                  placeholder="Filtrar..."
                  onChange={this.filterList}
                />
              </div>
            </div>
          )}
          <div style={{ marginBottom: "20px" }} />
          <div style={{ minHeight: "400px" }}>
            {source && source.length ? (
              <table className="table demand__resume">
                <thead>
                  <tr>
                    <th> Código </th>
                    <th> Nombre </th>
                    <th style={{ width: "153px" }}> Progreso </th>
                  </tr>
                </thead>
                <tbody style={{ fontWeight: 400 }}>
                  {source.map((item: any) => (
                    <CrossListItem key={item.unit.id} {...item} />
                  ))}
                </tbody>
              </table>
            ) : (
              <p>No hay resultados para mostrar</p>
            )}
          </div>
        </MessageBody>
      </Message>
    )
  }
}
