import React, { Component } from "react"
import { Link } from "react-router-dom"
import logo from "../../../assets/svg/logo_Darwined.svg"
import { AuthConsumer } from "../../contexts/AuthContext"
import Icon from "@mdi/react"
import { mdiLogout, mdiBell, mdiChevronLeft, mdiHome } from "@mdi/js"

const Burger = ({ isActive, onClick }: any) => {
  return (
    <button
      className={
        isActive
          ? "button is-text navbar-burger navbar-burger-left is-active"
          : "button is-text navbar-burger navbar-burger-left"
      }
      aria-label="menu"
      aria-expanded={isActive}
      onClick={onClick}
    >
      <span aria-hidden="true" />
      <span aria-hidden="true" />
      <span aria-hidden="true" />
    </button>
  )
}

const HAS_NAVBAR_FIXED_TOP = "has-navbar-fixed-top"

type HeaderState = {
  isActive: boolean
}

type HeaderProps = {}

class Header extends Component<HeaderProps, HeaderState> {
  state = {
    isActive: false
  }

  componentDidMount() {
    document.body.classList.add(HAS_NAVBAR_FIXED_TOP)
  }

  componentWillUnmount() {
    document.body.classList.remove(HAS_NAVBAR_FIXED_TOP)
  }

  toggleNav = () => {
    this.setState(state => ({
      isActive: !state.isActive
    }))
  }

  render() {
    return (
      <AuthConsumer>
        {(authContext: any) => (
          <header>
            <nav className="navbar is-fixed-top" role="navigation" aria-label="main navigation">
              <div className="navbar-brand">
                <a className="navbar-item" style={{ padding: 0 }}>
                  <img
                    src={logo}
                    alt="DarwinEd"
                    width="180"
                    height="30"
                    style={{ maxHeight: "2.1rem" }}
                  />
                </a>
                <div className="is-hidden navbar-item navbar-contextual is-hidden-desktop">
                  <div>
                    <button className="button is-text has-text-link">
                      <span className="icon" style={{ fontSize: "1.2em" }}>
                        <Icon path={mdiChevronLeft} />
                      </span>
                    </button>
                    <button className="button is-text has-text-link">
                      <span className="icon" style={{ fontSize: "1.2em" }}>
                        <Icon path={mdiHome} />
                      </span>
                    </button>
                  </div>
                  <nav>
                    <h3>Título</h3>
                  </nav>
                  <div style={{ visibility: "hidden" }}>
                    <button className="button is-text has-text-link">
                      <span className="icon" style={{ fontSize: "1.2em" }}>
                        <Icon path={mdiChevronLeft} />
                      </span>
                    </button>
                    <button className="button is-text has-text-link">
                      <span className="icon" style={{ fontSize: "1.2em" }}>
                        <Icon path={mdiHome} />
                      </span>
                    </button>
                  </div>
                </div>
                <Burger isActive={this.state.isActive} onClick={this.toggleNav} />
              </div>
              <div className={this.state.isActive ? "navbar-menu is-active" : "navbar-menu"}>
                <div className="navbar-start" />
                <div className="navbar-end">
                  {}
                  <Link to="#" className="navbar-item">
                    <span className="icon" style={{ fontSize: "1.2em" }}>
                      <Icon path={mdiBell} />
                    </span>
                    <span>
                      <span className="is-hidden-desktop">
                        {"\u00A0"}
                        Notifications <span style={{ color: "lightgrey" }}>(0)</span>
                      </span>
                    </span>
                  </Link>
                  {}
                  <div className="navbar-item has-dropdown is-hoverable">
                    <Link to="#" className="navbar-link">
                      <span style={{ textTransform: "capitalize" }}>
                        {" "}
                        {authContext.auth.username}{" "}
                      </span>
                    </Link>
                    <div className="navbar-dropdown is-right">
                      <hr className="navbar-divider" />
                      <a
                        onClick={e => {
                          e.preventDefault()
                          window.location.href = `/logout?redirect=${
                            authContext.auth.project.logoutUrl
                          }`
                        }}
                        className="navbar-item"
                      >
                        <span className="icon is-small">
                          <Icon path={mdiLogout} />
                        </span>
                        {"\u00A0"}
                        <span>Salir</span>
                      </a>
                    </div>
                  </div>
                  <div className="navbar-item has-dropdown is-hoverable">
                    <Link to="#" className="navbar-link">
                      Descargables
                    </Link>
                    <div className="navbar-dropdown is-right">
                      <a
                        target="_blank"
                        href="https://s3-us-west-2.amazonaws.com/files.foris.cl/public/Instructivo+Editor+de+Demandas.pdf"
                        className="navbar-item"
                      >
                        Manual de uso
                      </a>
                      <a
                        target="_blank"
                        href="https://s3-us-west-2.amazonaws.com/files.foris.cl/public/Reporte+-+PROYECCION+20192.xlsx"
                        className="navbar-item"
                      >
                        Reporte proyección 2019-2
                      </a>
                      <a
                        target="_blank"
                        href="https://s3-us-west-2.amazonaws.com/files.foris.cl/public/Detalle+Reporte+-++PROYECCION+20192.xlsx"
                        className="navbar-item"
                      >
                        Detalle reporte proyección 2019-2
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </nav>
          </header>
        )}
      </AuthConsumer>
    )
  }
}

export default Header
