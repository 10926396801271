import Dashboard from "./modules/demand-editor/pages/dashboard/DashboardContainer"
import Editor from "./modules/demand-editor/pages/editor/EditorContainer"
import CLEditor from "./modules/demand-editor/pages/crosslist-editor/CLEditorContainer"
import AddPageContainer from "./modules/demand-editor/pages/crosslist-editor/AddPageContainer"
import EditPageContainer from "./modules/demand-editor/pages/crosslist-editor/EditPageContainer"

export const base = "/forecast"

export const moduleRoutes = [
  {
    path: `${base}/demand-editor/crosslists/edit/:unitId`,
    component: EditPageContainer,
    name: "forecast@demand-editor/cleditor/edit"
  },
  {
    path: `${base}/demand-editor/crosslists/add`,
    component: AddPageContainer,
    name: "forecast@demand-editor/cleditor/add"
  },
  {
    path: `${base}/demand-editor/crosslists`,
    component: CLEditor,
    name: "forecast@demand-editor/cleditor"
  },
  {
    path: `${base}/demand-editor/unit/:unitId`,
    component: Editor,
    name: "forecast@demand-editor/editor"
  },
  {
    path: `${base}/demand-editor`,
    component: Dashboard,
    name: "forecast@demand-editor"
  },
  {
    path: base,
    name: "forecast@home"
  }
]
