import React, { Component } from "react"
import { withApollo, WithApolloClient } from "react-apollo"
import Autosuggest from "react-autosuggest"
import { Subject } from "rxjs"
import { debounceTime } from "rxjs/operators"
import { GET_SUGGESTIONS_QUERY } from "../queries"
import styled from "styled-components"

const SearchDemandResult = styled.div`
  line-height: 1.4em;
  .departmentProgram {
    font-size: 0.9em;
  }
  .department {
    display: inline-block;
    font-size: 1em;
  }
  .program {
    display: inline-block;
    font-size: 1em;
  }
  .demand {
    font-size: 1em;
    font-weight: 500;
  }
  .courseName {
  }
  .curriculumName {
    border-radiu
  }
  .levelName {}
  .crossList {}
`
interface SearchFieldProps {
  projectId: number
  excludeFromResults: any
  onSelectDemand: Function
}

interface SearchFieldState {
  value: string
  suggestions: any
  suggestionsCopy_WRITE: any
  suggestionsCopy_READ: any
  isLoading: boolean
  debounced: string
}

type SearchFieldPropsWithApollo = WithApolloClient<SearchFieldProps>

const DEBOUNCE_TIME = 500
class SearchField extends Component<SearchFieldPropsWithApollo, SearchFieldState> {
  onSearch$ = new Subject()
  subscription: any

  state = {
    value: "",
    suggestions: [],
    suggestionsCopy_WRITE: [],
    suggestionsCopy_READ: [],
    isLoading: false,
    debounced: ""
  }

  componentDidMount() {
    this.subscription = this.onSearch$
      .pipe(debounceTime(DEBOUNCE_TIME))
      .subscribe((debounced: any) => this.makeQueryAfterDevounceTime(debounced))
  }

  componentWillUnmount() {
    if (this.subscription) {
      this.subscription.unsubscribe()
    }
  }

  componentDidUpdate(prevProps: any, prevState: any, snapshot: any) {
    if (prevProps !== this.props) {
      const excludes = Object.keys(this.props.excludeFromResults).map((key: string) => key)
      if (excludes.length) {
        let suggestionsWithOutExcludes = this.state.suggestionsCopy_READ.filter((item: any) => {
          return !excludes.includes(item.id)
        })
        this.setState({ suggestionsCopy_WRITE: suggestionsWithOutExcludes })
      } else {
        this.setState({ suggestionsCopy_WRITE: this.state.suggestionsCopy_READ })
      }
    }
  }

  async makeQueryAfterDevounceTime(value: string) {
    if (value.length < 3) return

    let result
    const {
      data: {
        project: { searchByDemand }
      }
    } = await this.props.client.query({
      query: GET_SUGGESTIONS_QUERY,
      variables: { projectId: this.props.projectId, q: value }
    })

    if (!searchByDemand.length) result = [{}]
    else result = searchByDemand

    const excludes = Object.keys(this.props.excludeFromResults).map((key: string) => key)

    if (excludes.length) {
      result = result.filter((item: any) => {
        return !excludes.includes(item.id)
      })
    }

    this.setState({
      isLoading: false,
      suggestions: result,
      suggestionsCopy_WRITE: result,
      suggestionsCopy_READ: result
    })
  }

  onChange = (event: any, { newValue }: any) => {
    this.setState({
      value: newValue
    })
  }

  onSuggestionsFetchRequested = ({ value }: any) => {
    if (value === this.state.value) {
      this.setState({ suggestions: this.state.suggestionsCopy_WRITE })
    } else {
      this.setState({ isLoading: true })
      this.onSearch$.next(value)
    }
  }

  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: []
    })
  }

  getSuggestionValue = (suggestion: any) => {
    //if (!Object.keys(suggestion).length) return ""

    return this.state.value
  }

  onSuggestionSelected = (event: any, { suggestion }: any) => {
    if (suggestion && Object.keys(suggestion).length > 0) {
      this.props.onSelectDemand(suggestion)
    }
  }

  renderSuggestion = (suggestion: any) => {
    if (!Object.keys(suggestion).length) return <span>No se han encontrado resultados.</span>

    return (
      <SearchDemandResult>
        <div className="departmentProgram">
          <div className="program">{suggestion.programName}</div>
          {" | "}
          <div className="department">{suggestion.course.curriculum.program.department.name}</div>
        </div>
        <div className="demand">
          <span className="courseName">
            {suggestion.course.label}{" "}
            <span style={{ backgroundColor: "#e6e6e6", borderRadius: "3px", padding: "2px 4px" }}>
              {suggestion.course.code} {suggestion.course.name}
            </span>
          </span>
          {" | "}
          <span className="curriculumName">{suggestion.curriculumName}</span>
          {" | "}
          <span className="levelName">Nivel: {suggestion.course.level}</span>
          {suggestion.relatedUnits &&
            suggestion.relatedUnits.crosslist &&
            suggestion.relatedUnits.crosslist.code && (
              <>
                {" | "}
                <span className="crossList">
                  Lista cruzada: {suggestion.relatedUnits.crosslist.code}
                </span>
              </>
            )}
        </div>
      </SearchDemandResult>
    )
  }

  render() {
    const { value, suggestions, isLoading } = this.state
    const inputProps = {
      placeholder: "Buscar...",
      value,
      onChange: this.onChange
    }

    return (
      <div className={isLoading ? "autosuggest__container isLoading" : "autosuggest__container"}>
        <Autosuggest
          suggestions={suggestions}
          onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
          onSuggestionsClearRequested={this.onSuggestionsClearRequested}
          onSuggestionSelected={this.onSuggestionSelected}
          getSuggestionValue={this.getSuggestionValue}
          renderSuggestion={this.renderSuggestion}
          inputProps={inputProps}
          focusInputOnSuggestionClick={false}
        />
      </div>
    )
  }
}

export default withApollo(SearchField)
