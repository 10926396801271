import React, { Component } from "react"
import fakeAuth from "../../authClient"

const AuthContext = React.createContext({})

class AuthProvider extends Component<any, any> {
  state = {
    value: {
      auth: fakeAuth.getUserData()
    }
  }

  render() {
    return (
      <AuthContext.Provider value={this.state.value}>{this.props.children}</AuthContext.Provider>
    )
  }
}

const AuthConsumer = AuthContext.Consumer

export { AuthProvider, AuthConsumer }
