import React from "react"
import styled from "styled-components"

const SpinnerWrapper = styled.div`
  display: block;
  margin: 0 auto;
  text-align: center;
  width: 100px;
  height: 90px;
  line-height: 1em;
  & > p {
    text-align: center;
  }
`
const Spinner = styled.div`
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
  text-align: center;

  & > div {
    position: absolute;
    border: 4px solid #8cccb2;
    opacity: 1;
    border-radius: 50%;
    animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
  }
  & > div:nth-child(2) {
    animation-delay: -0.5s;
  }
  @keyframes lds-ripple {
    0% {
      top: 28px;
      left: 28px;
      width: 0;
      height: 0;
      opacity: 1;
    }
    100% {
      top: -1px;
      left: -1px;
      width: 58px;
      height: 58px;
      opacity: 0;
    }
  }
`
const Loading = () => {
  return (
    <SpinnerWrapper className="loadingContainer">
      <Spinner>
        <div />
        <div />
      </Spinner>
      <p>Cargando...</p>
    </SpinnerWrapper>
  )
}

export default Loading
