import React, { Component, Props } from "react"
import { Link } from "react-router-dom"
import { withRouter, RouteComponentProps } from "react-router-dom"
import styled from "styled-components"
import { ISubject } from "../models"

const SubjectListWrapper = styled.tr`
  cursor: pointer;
  &:hover {
    background-color: #f5f5f5;
  }
  & > a {
    overflow: hidden;
    display: block;
  }
`

const Item = styled.div`
  border: 1px solid grey
  width: 100%;
`

interface SubjectListRouteProps extends RouteComponentProps<{}>, Props<{}> {
  history: any
}

class SubjectLisItem extends Component<SubjectListRouteProps & ISubject> {
  render() {
    const {
      subject,
      unit: { label, id, progress }
    }: ISubject = this.props

    const handleClick = () => {
      this.props.history.push(`/forecast/demand-editor/unit/${id}`)
    }

    return (
      <SubjectListWrapper onClick={handleClick}>
        <td className="alignMiddle" style={{ width: "100px" }}>
          {" "}
          {subject}{" "}
        </td>
        <td className="alignMiddle"> {label} </td>
        <td className="alignMiddle">
          <Item>
            <div
              style={{
                backgroundColor: "#049882",
                height: "10px",
                width: `${Number(progress) * 100}%`
              }}
            />
          </Item>
        </td>
      </SubjectListWrapper>
    )
  }
}

export default withRouter(SubjectLisItem)
