import React, { Component, Fragment } from "react"
import { withRouter, Link } from "react-router-dom"
import { graphql } from "react-apollo"
import styled from "styled-components"
import { EDIT_DEMAND } from "../queries"
import { isNumber } from "../../../helpers"

import { ToastContainer, Slide, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

const TableRow = styled.tr`
  &.disabled {
    opacity: 0.7;
    background-color: #403d3d1c;
    & input {
      pointer-events: none;
    }
  }
`

type Demand = {
  adjusted: string | number
  checked: boolean
  hasError: boolean
}

type DemandValue = {
  [key: string]: Demand
}

interface TableState {
  isLoading: boolean
  demandsFormValues: DemandValue
  countOwnDemands: number
}

class Table extends Component<any, TableState> {
  state = { isLoading: false, demandsFormValues: {} as DemandValue, countOwnDemands: 0 }

  componentWillReceiveProps(nextProps: any) {
    if (nextProps.project !== this.props.project) {
      const unit = nextProps.project.unit
      this.updateParentData(unit)
    }
  }

  componentDidMount() {
    this.updateParentData(this.props.project.unit)

    let formValues: any = this.refreshDeamnds(this.props.project.unit.demands)
    this.setState({ demandsFormValues: formValues })

    let count = 0
    this.props.project.unit.demands.forEach((item: any) => {
      if (item.own) count++
    })
    this.setState({ countOwnDemands: count })
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    if (prevProps.project.unit.demands !== this.props.project.unit.demands) {
      let formValues: any = this.refreshDeamnds(this.props.project.unit.demands)
      this.setState({ demandsFormValues: formValues })
    }
  }

  refreshDeamnds(demands: any) {
    let obj: any = {}
    demands.map((demand: any) => {
      obj[demand.id] = {
        adjusted: Number(demand.adjusted),
        checked: demand.checked
      }
    })
    return obj
  }

  updateParentData(unit: any) {
    //UNIT KIND
    this.props.updateUnitType(unit.kind)

    // TITLES
    if (unit.kind === "LEVEL") {
      this.props.updateTitle(
        unit.kind,
        `${unit.programName} | ${unit.curriculumName} | Nivel: ${unit.tags.level}`
      )
    } else if (unit.kind === "SUBJECT") {
      this.props.updateTitle(unit.kind, `${unit.tags.subject} ${unit.label}`)
    } else {
      this.props.updateTitle(unit.kind, unit.label)
    }

    // UNIT LINKS
    this.props.updateNavLinks(unit.previusUnit, unit.nextUnit)
  }

  changeAdjustedValue = (e: any, demandId: string) => {
    e.preventDefault()

    let hasError: boolean = !isNumber(e.target.value) ? true : false
    let currentValue: any = this.state.demandsFormValues[demandId]

    this.setState({
      demandsFormValues: {
        ...this.state.demandsFormValues,
        [demandId]: {
          ...currentValue,
          adjusted: e.target.value,
          hasError: hasError
        }
      }
    })
  }

  changeCheckedValue = (e: any, demandId: string) => {
    const currentValue: any = this.state.demandsFormValues[demandId]

    this.setState({
      demandsFormValues: {
        ...this.state.demandsFormValues,
        [demandId]: {
          ...currentValue,
          checked: e.target.checked
        }
      }
    })
  }

  formIsValid(values: any) {
    let totalErrors = 0

    for (var i = 0, length = Object.keys(values).length; i < length; i++) {
      const currentKey = Object.keys(values)[i]

      if (!isNumber(values[currentKey].adjusted)) {
        const currentValue: any = this.state.demandsFormValues[currentKey]
        this.setState({
          demandsFormValues: {
            ...this.state.demandsFormValues,
            [currentKey]: {
              ...currentValue,
              hasError: true
            }
          }
        })
        totalErrors++
      }
    }

    return !totalErrors
  }

  handleSaveButton = (e: any) => {
    e.preventDefault()

    const values: any = this.state.demandsFormValues
    const unitId: string = this.props.project.unit.id

    if (!this.formIsValid(values)) return

    this.setState({ isLoading: true })

    this.props
      .mutate({
        variables: {
          unitId: unitId,
          demandAdjustments: (() => {
            return Object.keys(values).map((key: string) => {
              return {
                demandId: key,
                adjusted: Number(values[key].adjusted),
                confirmed: Boolean(values[key].checked)
              }
            })
          })()
        }
      })
      .then((data: any) => {
        toast.info("La demanda se ha actualizado correctamente", {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          transition: Slide
        })
        this.setState({ isLoading: false })
      })
      .catch((error: any) => {
        this.setState({ isLoading: false })
      })
  }

  handleReturnButton = (e: any) => {
    e.preventDefault()
    this.props.history.push("/forecast/demand-editor")
  }

  render() {
    const demands: any = this.props.project
    const currentValues: any = this.state.demandsFormValues
    return (
      <Fragment>
        <ToastContainer />
        <table className="table demand__editor">
          <thead>
            <TableRow>
              {demands.unit.kind !== "LEVEL" && <th> Carrera</th>}
              {demands.unit.kind !== "LEVEL" && <th> Currículo</th>}
              <th>Código</th>
              <th style={{ textAlign: "center" }}>Alias</th>
              <th>Asignatura</th>
              <th style={{ textAlign: "center" }}>DDA Proyectada</th>
              <th style={{ textAlign: "center" }}>DDA Ajustada</th>
              <th style={{ textAlign: "center" }}>Confirmada</th>
              {demands.unit.kind !== "CROSSLIST" && (
                <th style={{ textAlign: "center" }}>Lista Cruzada</th>
              )}
            </TableRow>
          </thead>
          <tbody>
            {demands.unit.demands.map((demand: any) => (
              <TableRow key={demand.id} className={!demand.own ? "disabled" : ""}>
                {demands.unit.kind !== "LEVEL" && (
                  <td style={{ width: "115px" }}> {demand.course.curriculum.program.name}</td>
                )}
                {demands.unit.kind !== "LEVEL" && (
                  <td style={{ width: "120px" }}>
                    <Link to={demand.relatedUnits.level.id}>{demand.course.curriculum.name}</Link>
                  </td>
                )}
                <td style={{ width: "70px" }}> {demand.course.code} </td>
                <>
                  {demand.relatedUnits.subject.id ? (
                    <td style={{ width: "100px", textAlign: "center" }}>
                      <Link to={demand.relatedUnits.subject.id}>{demand.course.label}</Link>
                    </td>
                  ) : (
                    <td />
                  )}
                </>

                <td>
                  <strong>{demand.course.name}</strong>
                </td>
                <td style={{ width: "100px", textAlign: "center" }}>{demand.value}</td>
                <td style={{ width: "100px", textAlign: "center" }}>
                  {currentValues[demand.id] && (
                    <div className="field">
                      <div className="control" style={{ textAlign: "center" }}>
                        {!demand.own || currentValues[demand.id].checked ? (
                          <>
                            {currentValues[demand.id].adjusted !== null
                              ? currentValues[demand.id].adjusted
                              : ""}
                          </>
                        ) : (
                          <input
                            className={
                              currentValues[demand.id].hasError ? "input is-danger" : "input"
                            }
                            type="text"
                            pattern="[0-9]*"
                            onChange={e => this.changeAdjustedValue(e, demand.id)}
                            name={`adjusted_${demand.id}`}
                            defaultValue={
                              currentValues[demand.id].adjusted
                                ? currentValues[demand.id].adjusted
                                : ""
                            }
                            placeholder={`${currentValues[demand.id].adjusted}`}
                          />
                        )}
                      </div>
                    </div>
                  )}
                </td>
                <td style={{ width: "100px", textAlign: "center" }}>
                  <div className="field">
                    <input
                      onClick={e => this.changeCheckedValue(e, demand.id)}
                      type="checkbox"
                      name={`check_${demand.id}`}
                      defaultChecked={demand.checked}
                    />{" "}
                  </div>
                </td>

                {demands.unit.kind !== "CROSSLIST" ? (
                  <>
                    {demand.relatedUnits.crosslist.id ? (
                      <td style={{ width: "100px", textAlign: "center" }}>
                        <Link to={demand.relatedUnits.crosslist.id}>
                          {demand.relatedUnits.crosslist.code}
                        </Link>
                      </td>
                    ) : (
                      <td style={{ width: "100px", textAlign: "center" }}> </td>
                    )}
                  </>
                ) : (
                  <></>
                )}
              </TableRow>
            ))}
            {demands.unit.kind !== "LEVEL" && (
              <TableRow>
                <td colSpan={5}>TOTAL</td>
                <td style={{ textAlign: "center" }}>
                  {(() => {
                    let total: number = 0
                    demands.unit.demands.forEach((demand: any) => (total += demand.value))
                    return total
                  })()}
                </td>
                <td style={{ textAlign: "center" }}>
                  {(() => {
                    let total: number = 0
                    Object.entries(currentValues).forEach(([key, value]: any) => {
                      return (total += Number(currentValues[key].adjusted))
                    })
                    return total
                  })()}
                </td>
                <td />
                {demands.unit.kind === "SUBJECT" ? <td /> : <></>}
              </TableRow>
            )}
          </tbody>
        </table>
        <br />

        <div className="buttons demand_editor__buttons is-pulled-right">
          <span className="button is-link is-outlined" onClick={e => this.handleReturnButton(e)}>
            Volver
          </span>
          {this.state.countOwnDemands > 0 ? (
            <span
              className={this.state.isLoading ? "button is-link is-loading" : "button is-link"}
              onClick={e => this.handleSaveButton(e)}
            >
              Guardar
            </span>
          ) : (
            <></>
          )}
        </div>
      </Fragment>
    )
  }
}

const componentWithRouter: any = withRouter(Table)

export const TableWithMutation = graphql(EDIT_DEMAND)(componentWithRouter)
