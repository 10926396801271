import React, { Component, Props } from "react"
import { withRouter, RouteComponentProps } from "react-router-dom"
import fakeAuth from "../../authClient"

interface LogoutRouteProps extends RouteComponentProps<{}>, Props<{}> {
  history: any
}

class Logout extends Component<LogoutRouteProps> {
  redirectURL: string = "/"

  componentWillMount() {
    const urlParams = new URLSearchParams(window.location.search)
    if (urlParams.has("redirect")) {
      this.redirectURL = urlParams.get("redirect") || "/"
    }
  }

  componentDidMount() {
    fakeAuth.signOut((response: boolean) => {
      if (response) {
        if (this.redirectURL !== "/") {
          window.location.href = this.redirectURL
        } else {
          this.props.history.push(this.redirectURL)
        }
      }
    })
  }

  render() {
    return <p style={{ textAlign: "center" }}>cerrando sesión ...</p>
  }
}

export default withRouter(Logout)
