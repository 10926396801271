import React, { Component, Fragment, Props } from "react"
import { withApollo } from "react-apollo"
import { Link, withRouter, RouteComponentProps } from "react-router-dom"
import styled from "styled-components"
import Icon from "@mdi/react"
import { mdiTrashCan } from "@mdi/js"
import { DualListbox, SearchField } from "./index"
import { Query } from "react-apollo"
import FieldSelect from "../../dashboard/components/FieldSelect"
import { Loading } from "../../../../../../../common"
import { GET_DEPARTMENTS, GET_UNITS } from "../../dashboard/queries"
import { GET_UNIT_DETAIL } from "../../editor/queries"

import { ADD_CROSSLIST_MUTATION, EDIT_CROSSLIST_MUTATION } from "../queries"

import { ToastContainer, Slide, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

const TOAST_OPTIONS: object = {
  position: "bottom-right",
  autoClose: 5000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: false,
  transition: Slide
}

const ResumeTable = styled.table`
  tbody {
    tr {
      font-weight: 100;
      font-size: 0.9em;
    }
  }
`

const filterFactory = (array: object[]) => {
  return array.map((d: any) => ({
    value: d.id,
    label: d.name
  }))
}

interface FormCLEditRouteProps extends RouteComponentProps<{}>, Props<{}> {
  history: any
}

class FormCLEdit extends Component<FormCLEditRouteProps & any, any> {
  state = {
    name: "",
    description: "",
    demands: {} as any,
    department: {} as any,
    isLoading: false,
    nameHasEmptyError: false,
    departmentHasEmptyError: false,
    initialLoading: true,
    showConfirmation: false,
    showOnDeleteConfirmation: false,
    countDemandsWithCrossList: 0,
    originalCodeName: null
  }

  async componentDidMount() {
    const {
      data: { project }
    } = await this.props.client.query({
      query: GET_UNIT_DETAIL,
      variables: { projectId: this.props.projectId, unitId: this.props.unitId }
    })

    this.setState({
      name: project.unit.label || "",
      originalCodeName: project.unit.label,
      description: project.unit.description || "",
      department: {
        value: project.unit.department.id,
        label: project.unit.department.name
      }
    })

    //SET DEMAND OBJECT
    project.unit.demands.forEach((demand: any) => {
      this.onSelectDemand(demand)
    })

    this.setState({ initialLoading: false })
  }

  handleNameChange = (e: any, name: any) => {
    const value = e.target.value
    this.setState({ [name]: value, nameHasEmptyError: false })
  }

  handleSubmit = (e: any) => {
    e.preventDefault()

    if (this.state.name === "") {
      this.setState({ nameHasEmptyError: true })
      return
    }

    if (!this.state.department.hasOwnProperty("value")) {
      this.setState({ departmentHasEmptyError: true })
      return
    }

    const demands = this.state.demands
    let demandsWithCrossList = 0
    Object.keys(this.state.demands).forEach((key: any) => {
      if (
        demands[key]["relatedUnits"] &&
        demands[key]["relatedUnits"]["crosslist"] &&
        demands[key]["relatedUnits"]["crosslist"]["code"] &&
        demands[key]["relatedUnits"]["crosslist"]["code"] !== this.state.originalCodeName
      ) {
        demandsWithCrossList++
      }
    })
    if (demandsWithCrossList > 0) {
      this.setState({ countDemandsWithCrossList: demandsWithCrossList, showConfirmation: true })
    } else if (!Object.keys(this.state.demands).length) {
      this.setState({ showOnDeleteConfirmation: true })
    } else {
      this.sendForm()
    }
  }

  sendForm() {
    this.setState({ isLoading: true })
    let { name, demands, description }: any = this.state
    demands = this.demandsFactory(demands)

    let crossListEditObject = {
      id: this.props.unitId,
      input: {
        kind: "crosslist",
        label: name,
        projectId: this.props.projectId,
        departmentId: this.state.department.value,
        description,
        demands: demands
      }
    }

    this.props.client
      .mutate({
        mutation: EDIT_CROSSLIST_MUTATION,
        variables: crossListEditObject,
        update: (proxy: any, response: any) => {
          // if departmentId is null === || !currentProgramList --> 'not have cache for this query'
          if (this.props.currentDepartment && this.props.currentProgramList) {
            const departmentsToUpdate: any = []
            this.props.currentProgramList.forEach((item: any) => {
              departmentsToUpdate.push(item.id === "*" ? null : item.id)
            })

            // update all program in current department
            departmentsToUpdate.forEach((programId: any) => {
              try {
                const data = proxy.readQuery({
                  query: GET_UNITS,
                  variables: {
                    projectId: this.props.projectId,
                    filter: {
                      departmentId: this.props.currentDepartment,
                      programId: programId
                    }
                  }
                })

                const newCrossListView = data.project.dashboard.crosslistView.filter(
                  (item: any) => item.unit.id !== response.data.editUnit.unit.id
                )

                data.project.dashboard.crosslistView = newCrossListView
                proxy.writeQuery({
                  query: GET_UNITS,
                  variables: {
                    projectId: this.props.projectId,
                    filter: {
                      departmentId: this.props.currentDepartment,
                      programId: programId
                    }
                  },
                  data: data
                })
              } catch (e) {}
            })
          }
        }
      })
      .then((response: any) => {
        const status = response.data.editUnit.status
        if (status.code) {
          switch (status.code) {
            case 200:
              if (status.msg === "SUCCESS_DELETED") {
                toast.info("La lista se ha eliminado correctamente", TOAST_OPTIONS)
                setTimeout(() => {
                  this.props.history.push("/forecast/demand-editor/")
                }, 900)
              } else {
                toast.info("La lista se ha actualizado correctamente", TOAST_OPTIONS)
              }
              break
            case 409:
              if (status.msg === "CROSSLIST_LABEL_ALREADY_EXITS") {
                toast.warn("Ya existe una lista con este código", TOAST_OPTIONS)
              } else if (status.msg === "EMPTY_DEMAND") {
                toast.warn("No puedes crear una lista vacía", TOAST_OPTIONS)
              } else {
                toast.warn("Ocurrió un error, inténtalo nuevamente.", TOAST_OPTIONS)
              }
              break
          }
        }
        this.setState({ isLoading: false })
      })
      .catch((error: any) => {
        console.log(error)
      })
  }

  demandsFactory(demands: any) {
    let array: any[] = []

    Object.keys(demands).forEach((demandKey: any) => {
      array.push({
        id: demandKey,
        value: demands[demandKey].value,
        checked: demands[demandKey].checked,
        adjusted: demands[demandKey].adjusted
      })
    })

    return array
  }

  updateTotalValue = (): number => {
    let total: number = 0
    Object.entries(this.state.demands).forEach(([key, value]: any) => (total += value.value))
    return total
  }

  onSelectDemand = (demand: any) => {
    this.setState({ demands: { ...this.state.demands, [demand.id]: demand } })
  }

  onRemoveDemand = (demandId: string) => {
    let demands = this.state.demands
    if (demands.hasOwnProperty(demandId)) delete demands[demandId]
    this.setState({ demands })
  }

  render() {
    const {
      name,
      description,
      demands,
      nameHasEmptyError,
      departmentHasEmptyError,
      initialLoading,
      showConfirmation,
      countDemandsWithCrossList,
      showOnDeleteConfirmation
    } = this.state

    return (
      <Fragment>
        {initialLoading && (
          <>
            <br />
            <br />
            <Loading />
          </>
        )}
        {!initialLoading && (
          <>
            <div className="columns" style={{ marginBottom: "0px" }}>
              <div className="column is-4">
                <div className="field">
                  <label className="label">Código</label>
                  <div className="control">
                    <input
                      autoComplete="off"
                      name="name"
                      value={name}
                      onChange={e => this.handleNameChange(e, "name")}
                      type="text"
                      className={nameHasEmptyError ? "input is-danger" : "input"}
                    />
                    {nameHasEmptyError && (
                      <small style={{ color: "#ff3860", fontWeight: "bold" }}>
                        Este campo es requerido
                      </small>
                    )}
                  </div>
                </div>
              </div>
              <div className="column is-4">
                <div className="field" style={{ marginBottom: "0px" }}>
                  <Query query={GET_DEPARTMENTS} variables={{ projectId: this.props.projectId }}>
                    {({ loading, error, data }: any) => {
                      if (loading) return <p>Loading...</p>
                      if (error) return <p>Error :(</p>
                      return (
                        <FieldSelect
                          label="Escuela"
                          placeholder="Selecciona una escuela"
                          options={filterFactory(data.project.departments)}
                          defaultValue={this.state.department}
                          className={departmentHasEmptyError ? "with___error" : ""}
                          onChangeValue={(filterValue: any) => {
                            this.setState({
                              department: filterValue,
                              departmentHasEmptyError: false
                            })
                          }}
                        />
                      )
                    }}
                  </Query>
                  {departmentHasEmptyError && (
                    <small style={{ color: "#ff3860", fontWeight: "bold" }}>
                      Este campo es requerido
                    </small>
                  )}
                </div>
              </div>
            </div>

            <div className="columns">
              <div className="column is-4">
                <div className="field">
                  <label className="label">Nombre</label>
                  <div className="control">
                    <input
                      autoComplete="off"
                      name="description"
                      value={description}
                      onChange={e => this.handleNameChange(e, "description")}
                      type="text"
                      className="input"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="columns">
              <div className="column is-4">
                <div className="field">
                  <label className="label">Asignaturas</label>
                  <div className="control">
                    <SearchField
                      projectId={this.props.projectId}
                      onSelectDemand={this.onSelectDemand}
                      excludeFromResults={this.state.demands}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="columns">
              <div className="column">
                <ResumeTable className="table demand__editor">
                  <thead>
                    <tr>
                      <th>Carrera</th>
                      <th>Currículo</th>
                      <th>Código</th>
                      <th>Alias</th>
                      <th>Asignatura</th>
                      <th>Lista Cruzada</th>
                      <th>Demanda</th>
                      <th />
                    </tr>
                  </thead>
                  {Object.keys(demands).length ? (
                    <tbody>
                      {Object.keys(demands).map((key: string) => {
                        if (demands[key]) {
                          return (
                            <tr key={demands[key].id}>
                              <td>{demands[key].programName}</td>
                              <td>{demands[key].curriculumName}</td>
                              <td>{demands[key].course.code}</td>
                              <td>{demands[key].courseLabel}</td>
                              <td>{demands[key].course.name}</td>
                              <td style={{ textAlign: "center" }}>
                                {demands[key].relatedUnits &&
                                demands[key].relatedUnits.crosslist ? (
                                  <Link
                                    to={`/forecast/demand-editor/unit/${
                                      demands[key].relatedUnits.crosslist.id
                                    }`}
                                    target="_blank"
                                  >
                                    {demands[key].relatedUnits.crosslist.code}
                                  </Link>
                                ) : (
                                  <></>
                                )}
                              </td>
                              <td style={{ textAlign: "center" }}>{demands[key].adjusted}</td>
                              <td>
                                <div className="buttons">
                                  <a
                                    className="button"
                                    onClick={e => {
                                      this.onRemoveDemand(demands[key].id)
                                    }}
                                  >
                                    <span className="icon is-small" style={{ paddingTop: "4px" }}>
                                      <Icon size="20" path={mdiTrashCan} />
                                    </span>
                                  </a>
                                </div>
                              </td>
                            </tr>
                          )
                        } else {
                          return <></>
                        }
                      })}
                      <tr style={{ backgroundColor: "#f1efef" }}>
                        <td colSpan={6}>TOTAL</td>
                        <td style={{ textAlign: "center" }}>{this.updateTotalValue()}</td>
                        <td />
                      </tr>
                    </tbody>
                  ) : (
                    <tbody />
                  )}
                </ResumeTable>
              </div>
            </div>

            <div className={showConfirmation ? "modal is-active" : "modal"}>
              <div className="modal-background" />
              <div className="modal-content">
                <div className="box">
                  <article className="media">
                    <div className="media-content">
                      <div className="content">
                        <p>
                          {countDemandsWithCrossList > 1
                            ? `Con esta acción se cambiarán ${countDemandsWithCrossList} asignaturas desde otras listas cruzadas a la presente.`
                            : `Con esta acción se cambiará ${countDemandsWithCrossList} asignatura desde otra lista cruzada a la presente.`}
                        </p>
                        <div className="columns">
                          <div
                            className="column"
                            style={{ paddingTop: "0px", paddingBottom: "0px" }}
                          >
                            <p className="is-pulled-right">¿Desea continuar?</p>
                          </div>
                        </div>
                        <div className="columns" style={{ marginBottom: "10px" }}>
                          <div
                            className="column"
                            style={{ paddingTop: "0px", paddingBottom: "0px" }}
                          >
                            <div className="buttons is-pulled-right">
                              <div className="buttons is-pulled-right">
                                <a
                                  onClick={e => {
                                    this.setState({ showConfirmation: false })
                                  }}
                                  className="button is-link is-outlined"
                                >
                                  Cancelar
                                </a>
                                <span
                                  className="button is-link"
                                  onClick={e => {
                                    this.setState({ showConfirmation: false })
                                    this.sendForm()
                                  }}
                                >
                                  Continuar
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </article>
                </div>
              </div>
            </div>
            {/* DELETE */}
            <div className={showOnDeleteConfirmation ? "modal is-active" : "modal"}>
              <div className="modal-background" />
              <div className="modal-content">
                <div className="box">
                  <article className="media">
                    <div className="media-content">
                      <div className="content">
                        <p>
                          Con esta acción se eliminará la presente lista cruzada dado que no
                          contiene demandas
                        </p>
                        <div className="columns">
                          <div
                            className="column"
                            style={{ paddingTop: "0px", paddingBottom: "0px" }}
                          >
                            <p className="is-pulled-right">¿Desea continuar?</p>
                          </div>
                        </div>
                        <div className="columns" style={{ marginBottom: "10px" }}>
                          <div
                            className="column"
                            style={{ paddingTop: "0px", paddingBottom: "0px" }}
                          >
                            <div className="buttons is-pulled-right">
                              <div className="buttons is-pulled-right">
                                <a
                                  onClick={e => {
                                    this.setState({ showOnDeleteConfirmation: false })
                                  }}
                                  className="button is-link is-outlined"
                                >
                                  Cancelar
                                </a>
                                <span
                                  className="button is-link"
                                  onClick={e => {
                                    this.setState({ showOnDeleteConfirmation: false })
                                    this.sendForm()
                                  }}
                                >
                                  Continuar
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </article>
                </div>
              </div>
            </div>
            {/* //DELETE */}
            <div className="columns">
              <div className="column">
                <div className="buttons demand_editor__buttons is-pulled-right">
                  <div className="buttons demand_editor__buttons is-pulled-right">
                    <Link
                      to={`/forecast/demand-editor/unit/${this.props.unitId}`}
                      className="button is-link is-outlined"
                    >
                      Volver
                    </Link>
                    <span
                      className={
                        this.state.isLoading ? "button is-link is-loading" : "button is-link"
                      }
                      onClick={e => this.handleSubmit(e)}
                    >
                      {this.props.actionName}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <ToastContainer />
          </>
        )}
      </Fragment>
    )
  }
}

export default withRouter(withApollo(FormCLEdit))
