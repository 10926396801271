import gql from "graphql-tag"

export const GET_UNIT_DETAIL = gql`
  query GET_UNIT_DETAIL($projectId: ID!, $unitId: ID!) {
    project(projectId: $projectId) {
      id
      unit(unitId: $unitId) {
        id
        kind
        label
        progress
        tags
        totalProjected
        totalAdjusted
        nextUnit
        previusUnit
        programName
        curriculumName
        description
        tags
        department {
          id
          name
        }
        demands {
          id
          course {
            id
            name
            label
            level
            code
            curriculum {
              id
              name
              program {
                id
                name
              }
            }
          }
          user {
            id
            username
            email
          }
          value
          adjusted
          checked
          equivalenceGroup
          relatedUnits
          own
          courseLabel
          programName
          curriculumName
        }
      }
    }
    status {
      code
      msg
    }
  }
`

export const EDIT_DEMAND = gql`
  mutation EditDemand($unitId: ID!, $demandAdjustments: [DemandAdjustment]) {
    editDemand(unitId: $unitId, demandAdjustments: $demandAdjustments) {
      id
      kind
      progress
      demands {
        id
        course {
          id
          name
        }
        user {
          id
          username
          email
        }
        value
        adjusted
        checked
        equivalenceGroup
      }
    }
  }
`
