import React, { Component, Fragment } from "react"
import { withApollo, WithApolloClient } from "react-apollo"
import { Link } from "react-router-dom"

import { DepartmentsFilter, ProgramsFilter } from "./index"
import { IFilterOptions } from "../models"
import { GET_UNITS } from "../queries"

import { ForecastContextConsumer } from "../../../../../context"

type FiltersProps = WithApolloClient<IFilterOptions & any>

class Filters extends Component<FiltersProps> {
  state = {
    value: null,
    departmentHasEmptyError: false,
    programHasEmptyError: false
  }

  componentDidMount() {
    this.setState({
      value: {
        project: this.props.project
      }
    })
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    const context = this.props.context
    if (prevProps !== this.props) {
      if (context.dashboard.currentDepartmentListSelected.value) {
        this.setState({ departmentHasEmptyError: false })
      }
      if (context.dashboard.currentProgramListSelected.value) {
        this.setState({ programHasEmptyError: false })
      }
    }
  }

  async searchUnits(e: any, department: any, program: any) {
    e.preventDefault()

    if (department.value === null) {
      this.setState({ departmentHasEmptyError: true })
      return false
    }

    if (program.value === null) {
      this.setState({ programHasEmptyError: true })
      return false
    }

    const filters: any = {
      departmentId: department ? department.value : null,
      programId: program.value === "*" ? null : program.value
    }

    const result = await this.props.client.query({
      query: GET_UNITS,
      variables: {
        projectId: this.props.projectId,
        filter: filters
      }
    })

    return result.data.project.dashboard
    /*
      .catch((error: any) => {
        console.log("there was an error sending the query", error)
        this.setState({ loadingResults: false })
      }) */
  }

  render() {
    return (
      <Fragment>
        <ForecastContextConsumer>
          {context => {
            if (!context) return <p>error...</p>
            const { departmentHasEmptyError, programHasEmptyError } = this.state
            return (
              <Fragment>
                {/* <!-- FILTERS ---> */}
                <div className="filters__container">
                  <div className="columns">
                    <div className="column is-9">
                      <div className="columns">
                        <div className="column">
                          <DepartmentsFilter
                            error={departmentHasEmptyError}
                            project={this.props.project}
                            context={context}
                          />

                          {departmentHasEmptyError && (
                            <small style={{ color: "#ff3860", fontWeight: "bold" }}>
                              Este campo es requerido
                            </small>
                          )}
                        </div>
                        <div className="column">
                          <ProgramsFilter
                            error={programHasEmptyError}
                            project={this.props.project}
                            context={context}
                          />
                          {programHasEmptyError && (
                            <small style={{ color: "#ff3860", fontWeight: "bold" }}>
                              Este campo es requerido
                            </small>
                          )}
                        </div>
                      </div>
                      {context.auth.role.permissions.crosslist.includes("create") && (
                        <Link
                          to="/forecast/demand-editor/crosslists/add"
                          className="is-pulled-left"
                          style={{ fontWeight: "normal", textDecoration: "underline" }}
                        >
                          Agregar lista cruzada
                        </Link>
                      )}
                    </div>
                    <div className="column">
                      <div
                        className="buttons is-pulled-left"
                        style={{ paddingTop: "32px" }}
                        onClick={async e => {
                          context.updateDashboardCurrentData("loadingSearch", true)
                          const result = await this.searchUnits(
                            e,
                            context.dashboard.currentDepartmentListSelected,
                            context.dashboard.currentProgramListSelected
                          )
                          context.updateDashboardCurrentData("unitView", result)
                          context.updateDashboardCurrentData("loadingSearch", false)
                        }}
                      >
                        <span className="button is-link is-outlined">Filtrar</span>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <!-- /FILTERS --> */}
                <div className="tabs is-boxed dashboardTabs">
                  <ul style={{ marginLeft: "0px" }}>
                    <li
                      className={context.dashboard.selectedTab === "curriculum" ? "is-active" : ""}
                    >
                      <a
                        onClick={e =>
                          context.updateDashboardCurrentData("selectedTab", "curriculum")
                        }
                      >
                        <span>Currículos</span>
                      </a>
                    </li>
                    <li className={context.dashboard.selectedTab === "subjects" ? "is-active" : ""}>
                      <a
                        onClick={e => context.updateDashboardCurrentData("selectedTab", "subjects")}
                      >
                        <span>Asignaturas</span>
                      </a>
                    </li>
                    <li
                      className={context.dashboard.selectedTab === "crosslists" ? "is-active" : ""}
                    >
                      <a
                        onClick={e =>
                          context.updateDashboardCurrentData("selectedTab", "crosslists")
                        }
                      >
                        <span>Listas cruzadas</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </Fragment>
            )
          }}
        </ForecastContextConsumer>
      </Fragment>
    )
  }
}

export default withApollo(Filters)
