import React, { Component, Fragment } from "react"
import { Link } from "react-router-dom"

class ListPage extends Component<any, any> {
  render() {
    const { list } = this.props
    return (
      <Fragment>
        <div className="columns">
          <div className="column">
            <h3>Listas cruzadas</h3>
          </div>
          <div className="column">
            <div className="buttons is-pulled-right">
              <Link
                className="button is-link is-outlined"
                to="/forecast/demand-editor/crosslists/add"
              >
                <span>Crear nueva</span>
              </Link>
            </div>
          </div>
        </div>
        <table className="table demand__resume">
          <thead>
            <tr>
              <th>Código</th>
            </tr>
          </thead>
          <tbody>
            {list.map((demand: any) => (
              <tr key={demand.id}>
                <td>
                  <Link to={`/forecast/demand-editor/crosslists/edit/${demand.id}`}>
                    {demand.label}
                  </Link>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </Fragment>
    )
  }
}

export default ListPage
