import React, { Component, Fragment, forwardRef } from "react"
import { Route, Switch, Redirect } from "react-router-dom"
import Main from "../../../modules/pages/Main"
import Catalogs from "../../../modules/catalogs"
import Wizard from "../../../modules/wizard"
import Projection from "../../../modules/forecast"

const moduleRoutes = [
  {
    path: "/catalogs/:subpage?",
    component: Catalogs
  },
  {
    path: "/wizard/:subpage?",
    component: Wizard
  },
  {
    path: "/forecast/:subpage?",
    component: Projection
  },
  {
    path: "/asignaciones"
  },
  {
    path: "/horarios"
  },
  {
    path: "/",
    component: Main
  }
]

class Shell extends Component<{}, {}> {
  render() {
    return (
      <Fragment>
        {window.location.href.split("/")[3] === "" && <Redirect to={"/forecast/demand-editor"} />}
        <Switch>
          {moduleRoutes.map(route => (
            <Route key={route.path} path={route.path} component={route.component} />
          ))}
        </Switch>
      </Fragment>
    )
  }
}

export default Shell
