import React, { Component, Fragment } from "react"

import Header from "./Header"

class DefaultLayout extends Component {
  render() {
    return (
      <Fragment>
        <Header />
        <main>{this.props.children}</main>
      </Fragment>
    )
  }
}

export default DefaultLayout
