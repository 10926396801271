import React, { Component, Props } from "react"
import { withRouter, RouteComponentProps } from "react-router-dom"
import { Link } from "react-router-dom"

import styled from "styled-components"
import { ILevel } from "../models"

const LevelWrapper = styled.div`
  cursor: pointer;
  &:hover {
    background-color: #f5f5f5;
  }
  & > a {
    font-weight: 400;
    overflow: hidden;
    display: block;
    color: #4486c3;
  }
`

const Item = styled.div`
  width: 100%;
  border: 1px solid #e0e0e0;
  & > div {
    -webkit-transition: all 1s ease-in-out;
    -moz-transition: all 1s ease-in-out;
    -o-transition: all 1s ease-in-out;
    transition: all 1s ease-in-out;
    background-color: #449d7a;
    height: 10px;
  }
}
`

interface LevelRouteProps extends RouteComponentProps<{}>, Props<{}> {
  history: any
}

class LevelItem extends Component<LevelRouteProps & ILevel> {
  render() {
    const {
      level,
      unit: { id, progress }
    }: ILevel = this.props

    return (
      <LevelWrapper className="column is-one-fifth">
        <Link to={`/forecast/demand-editor/unit/${id}`}>
          Semestre {level} <br />
          <Item>
            <div style={{ width: `${Number(progress) * 100}%` }} />
          </Item>
        </Link>
      </LevelWrapper>
    )
  }
}

export default withRouter(LevelItem)
