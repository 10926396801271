import React from "react"
import { Query } from "react-apollo"
import { DEFAULT_MESSAGES } from "../../../../../../common/config"
import { BreadCrumb, Loading } from "../../../../../../common"
import { withForecastContext, ForecastContextInterface } from "../../../../context"
import { Filters, UnitsResult } from "./components"
import { GET_FILTERS_DATA } from "./queries"

const breadCrumbItems = [{ label: "Editor de demanda", link: null }]

const Container = (props: any) => {
  return (
    <section className="main-section forecast">
      <div className="hero-body">
        <div className="container is-fluid">
          <BreadCrumb items={breadCrumbItems} />
          <div className="columns">
            <div className="column is-10">
              <div className="is-content demand__editor">
                <div className="content">{props.content}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

const Dashboard = ({ forecastContext }: { forecastContext?: ForecastContextInterface }) =>
  forecastContext && (
    <Query query={GET_FILTERS_DATA} variables={{ projectId: forecastContext.projectId }}>
      {({ loading, error, data }: any) => {
        if (loading) return <Container content={<Loading />} />
        if (error || data.status.code !== 200)
          return <Container content={<p>{DEFAULT_MESSAGES.ERROR}</p>} />
        if (data.project) {
          return (
            <Container
              content={
                <>
                  <Filters
                    {...data}
                    context={forecastContext}
                    projectId={forecastContext.projectId}
                  />
                  <UnitsResult {...forecastContext} projectId={forecastContext.projectId} />
                </>
              }
            />
          )
        } else {
          return <Container content={<p>{DEFAULT_MESSAGES.ERROR}</p>} />
        }
      }}
    </Query>
  )

export default withForecastContext(Dashboard)
