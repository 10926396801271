import React, { Component, Fragment } from "react"
import { Link } from "react-router-dom"
import { Query, withApollo, WithApolloClient } from "react-apollo"
import gql from "graphql-tag"
import { withRouter } from "react-router-dom"
import Icon from "@mdi/react"
import { mdiPencil, mdiArrowLeft, mdiArrowRight } from "@mdi/js"
import { BreadCrumb, Loading } from "../../../../../../common"
import { DEFAULT_MESSAGES } from "../../../../../../common/config"
import { ForecastContextConsumer, ForecastContextInterface } from "../../../../context"
import { TableWithMutation } from "./components"
import { GET_UNIT_DETAIL } from "./queries"

const breadCrumbItems = [
  { label: "Editor de demanda", link: "/forecast/demand-editor" },
  { label: "Editor", link: null }
]

const getTitle = (kind: string, label: string) => {
  let title
  switch (kind) {
    case "LEVEL":
      title = `${label}`
      break
    case "SUBJECT":
      title = `${label}`
      break
    case "CROSSLIST":
      title = `Lista cruzada: ${label}`
      break
  }
  return title
}

const Container = (props: any) => {
  return (
    <section className="main-section hero is-fullheight-with-navbar">
      <div className="hero-body">
        <div className="container is-fluid">
          <BreadCrumb items={breadCrumbItems} />
          <div className="columns">
            <div className="column">
              <div className="info">
                <div className="header level is-mobile" style={{ backgroundColor: "#4486c3" }}>
                  <div className="level-left">
                    <h1 className="is-1 has-text-weight-semibold">{props.viewTitle}</h1>
                  </div>

                  {props.unitType === "CROSSLIST" &&
                  props.auth.role.permissions.crosslist.includes("update") ? (
                    <div className="level-right">
                      <Link
                        to={`/forecast/demand-editor/crosslists/edit/${props.unitID}`}
                        className="button is-small is-primary is-outlined is-inverted"
                      >
                        <span className="icon is-small">
                          <Icon size="18" path={mdiPencil} />
                        </span>
                      </Link>
                    </div>
                  ) : (
                    <div className="level-right">
                      {props.prevLink && (
                        <Link
                          to={`/forecast/demand-editor/unit/${props.prevLink}`}
                          className="button is-small is-primary is-outlined is-inverted"
                          style={{ marginRight: "5px" }}
                        >
                          <span className="icon is-small">
                            <Icon size="18" path={mdiArrowLeft} />
                          </span>
                        </Link>
                      )}
                      {props.nextLink && (
                        <Link
                          to={`/forecast/demand-editor/unit/${props.nextLink}`}
                          className="button is-small is-primary is-outlined is-inverted"
                        >
                          <span className="icon is-small">
                            <Icon size="18" path={mdiArrowRight} />
                          </span>
                        </Link>
                      )}
                    </div>
                  )}
                </div>
                <div className="content" style={{ paddingTop: 0 }}>
                  {props.content}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

type EditorContainerProps = WithApolloClient<{} & any>

class EditorContainer extends Component<EditorContainerProps, any> {
  state = {
    title: null,
    previusUnit: null,
    nextUnit: null,
    unitType: null
  }

  componentWillMount() {
    const todo = this.props.client.readFragment({
      id: this.props.match.params.unitId,
      fragment: gql`
        fragment unitLabel on Unit {
          kind
          label
          tags
          programName
          curriculumName
        }
      `
    })

    if (todo && todo.kind) this.updateUnitType(todo.kind)

    // TITLES
    if ((todo && todo.label) || (todo && todo.kind === "LEVEL")) {
      let title
      title = todo.label
      if (todo.kind === "LEVEL") {
        title = `${todo.programName} | ${todo.curriculumName} | Nivel: ${todo.tags.level}`
      }
      if (todo.kind === "SUBJECT") {
        title = `${todo.tags.subject} ${todo.label}`
      }
      this.setState({ title: getTitle(todo.kind, title) })
    }
  }

  updateTitle = (kind: string, label: string) => {
    this.setState({ title: getTitle(kind, label) })
  }

  updateNavLinks = (prevId: string | null, nextId: string | null) => {
    this.setState({ previusUnit: prevId, nextUnit: nextId })
  }

  updateUnitType = (type: string) => {
    this.setState({ unitType: type })
  }

  render() {
    const unitID = this.props.match.params.unitId
    const { title, previusUnit, nextUnit, unitType } = this.state
    return (
      <ForecastContextConsumer>
        {context =>
          context && (
            <Container
              viewTitle={title}
              prevLink={previusUnit}
              nextLink={nextUnit}
              unitType={unitType}
              unitID={unitID}
              auth={context.auth}
              content={
                <Query
                  query={GET_UNIT_DETAIL}
                  variables={{ projectId: context.projectId, unitId: unitID }}
                >
                  {({ loading, error, data }: any) => {
                    if (loading) return <Loading />
                    if (error || data.status.code !== 200) return <p>{DEFAULT_MESSAGES.ERROR}</p>
                    return (
                      <TableWithMutation
                        {...data}
                        updateTitle={this.updateTitle}
                        updateNavLinks={this.updateNavLinks}
                        updateUnitType={this.updateUnitType}
                      />
                    )
                  }}
                </Query>
              }
            />
          )
        }
      </ForecastContextConsumer>
    )
  }
}

export default withRouter(withApollo(EditorContainer))
