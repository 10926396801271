import React, { Component } from "react"
import { withRouter } from "react-router-dom"
import { ForecastContextConsumer } from "../../../../context"
import { BreadCrumb } from "../../../../../../common"
import { FormCLEdit } from "./components"

const breadCrumbItems = [
  { label: "Editor de demanda", link: "/forecast/demand-editor" },
  { label: "Editar lista cruzada", link: null }
]

const Container = (props: any) => {
  return (
    <section className="main-section forecast">
      <div className="hero-body">
        <div className="container is-fluid">
          <BreadCrumb items={breadCrumbItems} />
          <div className="columns">
            <div className="column is-10">
              <div className="is-content has-background-white">
                <div className="content">{props.content}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

class EditPageContainer extends Component<any, any> {
  render() {
    const unitID = this.props.match.params.unitId
    return (
      <ForecastContextConsumer>
        {context =>
          context && (
            <Container
              content={
                <>
                  <h3>Editar lista cruzada</h3>
                  <FormCLEdit
                    actionName="Guardar cambios"
                    currentProgramList={context.dashboard.currentProgramList}
                    currentDepartment={(() => {
                      return context.dashboard &&
                        context.dashboard.currentDepartmentListSelected &&
                        context.dashboard.currentDepartmentListSelected.value &&
                        context.dashboard.currentDepartmentListSelected
                        ? context.dashboard.currentDepartmentListSelected.value
                        : null
                    })()}
                    unitId={unitID}
                    projectId={context.projectId}
                  />
                </>
              }
            />
          )
        }
      </ForecastContextConsumer>
    )
  }
}

export default withRouter(EditPageContainer)
