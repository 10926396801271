import React, { Component, Props } from "react"
import { BrowserRouter, Route, Switch, withRouter, RouteComponentProps } from "react-router-dom"
import ReactGA from "react-ga"
import { moduleRoutes } from "./routes"
import DefaultLayout from "../../common/components/layout/DefaultLayout"
import { AuthConsumer } from "../../common/contexts/AuthContext"
import { ForecastContextProvider } from "./context"

interface ForecastOutletRouteProps extends RouteComponentProps<{}>, Props<{}> {
  history: any
}

class ForecastOutletWithAnalytics extends Component<ForecastOutletRouteProps> {
  componentDidMount() {
    ReactGA.pageview(window.location.pathname + window.location.search)
  }

  componentDidUpdate(prevProps: any) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged()
    }
  }
  onRouteChanged() {
    ReactGA.pageview(window.location.pathname + window.location.search)
  }
  render() {
    return (
      <Switch>
        {moduleRoutes.map(route => (
          <Route key={route.path} path={route.path} component={route.component} />
        ))}
      </Switch>
    )
  }
}

class ForecastOutlet extends Component<ForecastOutletRouteProps> {
  render() {
    return (
      <Switch>
        {moduleRoutes.map(route => (
          <Route key={route.path} path={route.path} component={route.component} />
        ))}
      </Switch>
    )
  }
}

const ForecastOutletWithRouter = withRouter(
  Boolean(process.env.REACT_APP_ACTIVE_ANALYTICS) && process.env.REACT_APP_ANALYTICS_ID
    ? ForecastOutletWithAnalytics
    : ForecastOutlet
)

class Forecast extends Component {
  state = {
    value: {
      updateDashboardCurrentData: {} as any,
      dashboard: {} as any
    }
  }
  componentDidMount() {
    this.setState({
      value: {
        updateDashboardCurrentData: this.updateDashboard,
        dashboard: {
          currentDepartmentList: [],
          currentDepartmentListSelected: { label: null, value: null },
          currentProgramList: [],
          currentProgramListSelected: { label: null, value: null },
          selectedTab: "curriculum",
          unitView: {
            levelView: null,
            subjectView: null,
            crosslistView: null
          },
          currentUnitTitle: null,
          loadingSearch: false
        }
      }
    })
  }
  updateDashboard = (type: string, newValue: any) => {
    if (this.state.value.dashboard[type] !== newValue) {
      switch (type) {
        case "selectedTab":
        case "unitView":
        case "currentProgramListSelected":
        case "currentProgramList":
        case "currentUnitTitle":
        case "loadingSearch":
          this.setState({
            value: {
              ...this.state.value,
              dashboard: {
                ...this.state.value.dashboard,
                [type]: newValue
              }
            }
          })
          break
        case "currentDepartmentListSelected":
          this.setState({
            value: {
              ...this.state.value,
              dashboard: {
                ...this.state.value.dashboard,
                currentDepartmentListSelected: newValue["selectedValue"],
                currentProgramList: newValue["newProgramList"],
                currentProgramListSelected: { label: null, value: null }
              }
            }
          })
          break
      }
    }
  }

  render() {
    return (
      <AuthConsumer>
        {(authContext: any) => (
          <DefaultLayout>
            <BrowserRouter>
              <ForecastContextProvider
                value={{
                  ...this.state.value,
                  projectId: authContext.auth.project.id,
                  auth: authContext.auth
                }}
              >
                <ForecastOutletWithRouter />
              </ForecastContextProvider>
            </BrowserRouter>
          </DefaultLayout>
        )}
      </AuthConsumer>
    )
  }
}

export default Forecast
