import React from "react"
import { withForecastContext, ForecastContextInterface } from "../../../../context"
import { BreadCrumb } from "../../../../../../common"
import { FormCLAdd } from "./components"

const breadCrumbItems = [
  { label: "Editor de demanda", link: "/forecast/demand-editor" },
  { label: "Crear nueva lista cruzada", link: null }
]

const Container = (props: any) => {
  return (
    <section className="main-section forecast">
      <div className="hero-body">
        <div className="container is-fluid">
          <BreadCrumb items={breadCrumbItems} />
          <div className="columns">
            <div className="column is-10">
              <div className="is-content has-background-white">
                <div className="content">{props.content}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

const AddPageContainer = ({ forecastContext }: { forecastContext?: ForecastContextInterface }) =>
  forecastContext && (
    <Container
      content={
        <>
          <h3>Crear lista cruzada</h3>
          <FormCLAdd
            actionType="add"
            actionName="Crear lista cruzada"
            currentProgramList={forecastContext.dashboard.currentProgramList}
            currentDepartment={(() => {
              return forecastContext.dashboard &&
                forecastContext.dashboard.currentDepartmentListSelected &&
                forecastContext.dashboard.currentDepartmentListSelected.value &&
                forecastContext.dashboard.currentDepartmentListSelected
                ? forecastContext.dashboard.currentDepartmentListSelected.value
                : null
            })()}
            projectId={forecastContext.projectId}
            {...forecastContext}
          />
        </>
      }
    />
  )

export default withForecastContext(AddPageContainer)
