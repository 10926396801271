import React, { Component, Props } from "react"
import { withRouter, RouteComponentProps } from "react-router-dom"
import styled from "styled-components"
import { ICrossList } from "../models"

const CrossListWrapper = styled.tr`
  cursor: pointer;
  &:hover {
    background-color: #f5f5f5;
  }
  & > a {
    overflow: hidden;
    display: block;
  }
`
const Item = styled.div`
  border: 1px solid grey;
  width: 100%;
  & > div {
    background-color: #049882;
    height: 10px;
  }
`

interface CrossListRouteProps extends RouteComponentProps<{}>, Props<{}> {
  history: any
}

class CrossListItem extends Component<CrossListRouteProps & ICrossList> {
  render() {
    const {
      unit: { id, progress, label, description }
    }: ICrossList = this.props

    const handleClick = () => {
      this.props.history.push(`/forecast/demand-editor/unit/${id}`)
    }

    return (
      <CrossListWrapper onClick={handleClick}>
        <td className="alignMiddle" style={{ width: "120px" }}>
          {" "}
          {label}{" "}
        </td>
        <td className="alignMiddle"> {description} </td>
        <td className="alignMiddle">
          <Item>
            <div style={{ width: `${Number(progress) * 100}%` }} />
          </Item>
        </td>
      </CrossListWrapper>
    )
  }
}

export default withRouter(CrossListItem)
