import React, { Component } from "react"
import { Link } from "react-router-dom"

class CurriculumList extends Component {
  render() {
    return (
      <div className="testFragment">
        <p>
          <strong>CurriculumList page</strong> inside on <strong>catalogs</strong> module.
        </p>
        <Link to="/catalogs">
          <br />
          return to <strong>back</strong>
        </Link>
      </div>
    )
  }
}

export default CurriculumList
