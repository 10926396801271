const TYPE_PREFIX = "forecast_dashboard_"

const resolvers = {
  Mutation: {
    updateCurrentFilters: (_: any, { departmentId, programId }: any, { cache }: any) => {
      const data = {
        currentFilters: {
          __typename: "CurrentFilters",
          departmentId,
          programId
        }
      }
      cache.writeData({ data })
      return null
    },
    updateDepartmentFilterControl: (_: any, { filterValue }: any, { cache }: any) => {
      const data = {
        departmentFilterControl: {
          __typename: "DepartmentFilterControl",
          ...filterValue
        }
      }
      cache.writeData({ data })
      console.log("write data: ", data)
      return null
    },
    updateProgramFilterControl: (_: any, { filterValue }: any, { cache }: any) => {
      const data = {
        programFilterControl: {
          __typename: "ProgramFilterControl",
          ...filterValue
        }
      }
      cache.writeData({ data })
      return null
    }
  }
}

const defaults = {
  currentFilters: {
    __typename: "CurrentFilters",
    departmentId: null,
    programId: null
  },
  departmentFilterControl: {
    __typename: "DepartmentFilterControl",
    value: null,
    label: null,
    filterName: null
  },
  programFilterControl: {
    __typename: "ProgramFilterControl",
    value: null,
    label: null,
    filterName: null
  }
}

export default { resolvers, defaults }
