import gql from "graphql-tag"

export const GET_DEMANDS_AND_UNITS_QUERY = gql`
  query GET_DEMANDS_AND_UNITS_QUERY($projectId: ID!, $unitId: ID!) {
    project(projectId: $projectId) {
      id
      demands {
        id
        value
        checked
        adjusted
        course {
          id
          code
          name
        }
      }
      unit(unitId: $unitId) {
        id
        kind
        progress
        totalProjected
        totalAdjusted
        label
        description
        demands {
          id
          crosslistGroup {
            id
            value
            adjusted
            checked
            equivalenceGroup
            course {
              id
              name
              level
            }
          }
          course {
            id
            code
            name
          }
          value
          adjusted
          checked
          equivalenceGroup
          relatedUnits
        }
      }
    }
    status {
      code
      msg
    }
  }
`

export const ADD_CROSSLIST_MUTATION = gql`
  mutation addUnit($input: UnitInput) {
    addUnit(unitInput: $input) {
      status {
        code
        msg
      }
      unit {
        id
        kind
        label
        description
        progress
        totalDemand
        checkedCount
        programName
        curriculumName
        tags
        nextUnit
        previusUnit
        demands {
          id
          value
          adjusted
          checked
          equivalenceGroup
          relatedUnits
        }
      }
    }
  }
`

export const EDIT_CROSSLIST_MUTATION = gql`
  mutation editUnit($id: ID!, $input: UnitInput) {
    editUnit(unitId: $id, unitInput: $input) {
      status {
        code
        msg
      }
      unit {
        id
        kind
        label
        progress
        totalDemand
        checkedCount
        programName
        curriculumName
        tags
        nextUnit
        previusUnit
        demands {
          id
          value
          adjusted
          checked
          equivalenceGroup
          relatedUnits
        }
      }
    }
  }
`

export const GET_UNITS_BY_KIND = gql`
  query GET_UNITS_BY_KIND($projectId: ID!, $kind: UnitKind!) {
    project(projectId: $projectId) {
      id
      unitsByKind(kind: $kind) {
        id
        kind
        label
        description
      }
    }
    status {
      code
      msg
    }
  }
`

export const GET_SUGGESTIONS_QUERY = gql`
  query GET_SUGGESTIONS_QUERY($projectId: ID!, $q: String) {
    project(projectId: $projectId) {
      id
      searchByDemand(q: $q) {
        id
        programName
        curriculumName
        course {
          id
          level
          name
          code
          label
          curriculum {
            id
            program {
              id
              department {
                id
                code
                name
              }
            }
          }
        }
        relatedUnits
        value
        checked
        adjusted
        courseLabel
      }
    }
  }
`
