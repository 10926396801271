import React, { Component, Fragment, Props } from "react"
import { withApollo } from "react-apollo"
import { Link, withRouter, RouteComponentProps } from "react-router-dom"
import styled from "styled-components"
import Icon from "@mdi/react"
import { mdiTrashCan } from "@mdi/js"
import { DualListbox, SearchField } from "./index"
import { Query } from "react-apollo"
import FieldSelect from "../../dashboard/components/FieldSelect"
import { GET_DEPARTMENTS, GET_UNITS } from "../../dashboard/queries"

import { ADD_CROSSLIST_MUTATION, EDIT_CROSSLIST_MUTATION } from "../queries"

import { ToastContainer, Slide, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

const TOAST_OPTIONS: object = {
  position: "bottom-right",
  autoClose: 5000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: false,
  transition: Slide
}

const ResumeTable = styled.table`
  tbody {
    tr {
      font-weight: 100;
      font-size: 0.9em;
    }
  }
`
const filterFactory = (array: object[]) => {
  return array.map((d: any) => ({
    value: d.id,
    label: d.name
  }))
}

interface FormCLAddRouteProps extends RouteComponentProps<{}>, Props<{}> {
  history: any
}

class FormCLAdd extends Component<FormCLAddRouteProps & any, any> {
  state = {
    code: "",
    description: "",
    demands: {} as any,
    department: {} as any,
    isLoading: false,
    codeHasEmptyError: false,
    departmentHasEmptyError: false,
    showConfirmation: false,
    countDemandsWithCrossList: 0,
    codeHasIncorrectValue: false
  }

  handlecodeChange = (e: any) => {
    let value = e.target.value.toUpperCase()
    if (/^[A-Z\d._-]*$/.test(value)) {
      this.setState({ codeHasIncorrectValue: false })
    }
    this.setState({ code: value })
  }

  handleDescriptionChange = (e: any) => {
    const value = e.target.value
    this.setState({ description: value })
  }

  handleSubmit = (e: any, actionType: string) => {
    e.preventDefault()
    if (this.state.code === "") {
      this.setState({ codeHasEmptyError: true })
      return
    }
    if (!/^[A-Z\d._-]*$/.test(this.state.code)) {
      this.setState({ codeHasIncorrectValue: true })
      return
    }

    if (!this.state.department.hasOwnProperty("value")) {
      this.setState({ departmentHasEmptyError: true })
      return
    }

    const demands = this.state.demands
    let demandsWithCrossList = 0
    Object.keys(this.state.demands).forEach((key: any) => {
      if (
        demands[key]["relatedUnits"] &&
        demands[key]["relatedUnits"]["crosslist"] &&
        demands[key]["relatedUnits"]["crosslist"]["code"]
      ) {
        demandsWithCrossList++
      }
    })
    if (demandsWithCrossList > 0) {
      this.setState({ countDemandsWithCrossList: demandsWithCrossList, showConfirmation: true })
    } else {
      this.sendForm()
    }
  }

  sendForm() {
    this.setState({ isLoading: true })
    let { code, demands, description }: any = this.state
    demands = this.demandsFactory(demands)

    let crossListAddObject = {
      input: {
        kind: "crosslist",
        label: code,
        description,
        projectId: this.props.projectId,
        departmentId: this.state.department.value,
        demands
      }
    }

    this.props.client
      .mutate({
        mutation: ADD_CROSSLIST_MUTATION,
        variables: crossListAddObject,
        update: (proxy: any, response: any) => {
          // if departmentId is null === || !currentProgramList --> 'not have cache for this query'
          if (this.props.currentDepartment && this.props.currentProgramList) {
            const departmentsToUpdate: any = []
            this.props.currentProgramList.forEach((item: any) => {
              departmentsToUpdate.push(item.id === "*" ? null : item.id)
            })

            // update all program in current department
            departmentsToUpdate.forEach((programId: any) => {
              try {
                const data = proxy.readQuery({
                  query: GET_UNITS,
                  variables: {
                    projectId: this.props.projectId,
                    filter: {
                      departmentId: this.props.currentDepartment,
                      programId: programId
                    }
                  }
                })
                data.project.dashboard.crosslistView.push(response.data.addUnit)
                proxy.writeQuery({
                  query: GET_UNITS,
                  variables: {
                    projectId: this.props.projectId,
                    filter: {
                      departmentId: this.props.currentDepartment,
                      programId: programId
                    }
                  },
                  data: data
                })
              } catch (e) {}
            })
          }
        }
      })
      .then((response: any) => {
        const status = response.data.addUnit.status

        if (status.code) {
          switch (status.code) {
            case 200:
              toast.info("La lista se ha creado correctamente", TOAST_OPTIONS)
              setTimeout(() => {
                this.props.history.push("/forecast/demand-editor/")
              }, 900)
              break
            case 409:
              if (status.msg === "CROSSLIST_LABEL_ALREADY_EXITS") {
                toast.warn("Ya existe una lista con este código", TOAST_OPTIONS)
              } else if (status.msg === "EMPTY_DEMAND") {
                toast.warn("No puedes crear una lista vacía", TOAST_OPTIONS)
              } else {
                toast.warn("Ocurrió un error, inténtalo nuevamente.", TOAST_OPTIONS)
              }
              break
          }
        }
        this.setState({ isLoading: false })
      })
      .catch((error: any) => {
        console.log(error)
      })
  }

  demandsFactory(demands: any) {
    let array: any[] = []

    Object.keys(demands).forEach((demandKey: any) => {
      array.push({
        id: demandKey,
        value: demands[demandKey].value,
        checked: demands[demandKey].checked,
        adjusted: demands[demandKey].adjusted
      })
    })

    return array
  }

  updateTotalValue = (): number => {
    let total: number = 0
    Object.entries(this.state.demands).forEach(([key, value]: any) => (total += value.value))
    return total
  }

  onSelectDemand = (demand: any) => {
    this.setState({
      demands: { ...this.state.demands, [demand.id]: demand }
    })
  }

  onRemoveDemand = (demandId: string) => {
    let demands = this.state.demands
    if (demands.hasOwnProperty(demandId)) delete demands[demandId]

    this.setState({ demands })
  }

  render() {
    const {
      code,
      description,
      demands,
      codeHasEmptyError,
      departmentHasEmptyError,
      showConfirmation,
      countDemandsWithCrossList,
      codeHasIncorrectValue
    } = this.state

    return (
      <Fragment>
        <div className="columns" style={{ marginBottom: "0px" }}>
          <div className="column is-4">
            <div className="field">
              <label className="label">Código</label>
              <div className="control">
                <input
                  autoComplete="off"
                  name="code"
                  value={code}
                  onChange={e => this.handlecodeChange(e)}
                  type="text"
                  className={
                    codeHasEmptyError || codeHasIncorrectValue ? "input is-danger" : "input"
                  }
                />
                {codeHasEmptyError && (
                  <small style={{ color: "#ff3860", fontWeight: "bold" }}>
                    Este campo es requerido
                  </small>
                )}
                {codeHasIncorrectValue && (
                  <div style={{ lineHeight: "14px", marginTop: "3px" }}>
                    <small style={{ color: "#ff3860", fontWeight: "bold", fontSize: "13.5px" }}>
                      El código debe contener sólo caracteres alfanuméricos, puntos y guiones
                    </small>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="column is-4">
            <div className="field" style={{ marginBottom: "0px" }}>
              <Query query={GET_DEPARTMENTS} variables={{ projectId: this.props.projectId }}>
                {({ loading, error, data }: any) => {
                  if (loading) return <p>Loading...</p>
                  if (error) return <p>Error :(</p>
                  return (
                    <FieldSelect
                      label="Escuela"
                      placeholder="Selecciona una escuela"
                      options={filterFactory(data.project.departments)}
                      defaultValue={this.state.department}
                      className={departmentHasEmptyError ? "with___error" : ""}
                      onChangeValue={(filterValue: any) => {
                        this.setState({ department: filterValue, departmentHasEmptyError: false })
                      }}
                    />
                  )
                }}
              </Query>
              {departmentHasEmptyError && (
                <small style={{ color: "#ff3860", fontWeight: "bold" }}>
                  Este campo es requerido
                </small>
              )}
            </div>
          </div>
        </div>

        <div className="columns">
          <div className="column is-4">
            <div className="field">
              <label className="label">Nombre</label>
              <div className="control">
                <input
                  autoComplete="off"
                  name="description"
                  value={description}
                  onChange={e => this.handleDescriptionChange(e)}
                  type="text"
                  className="input"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="columns">
          <div className="column is-4">
            <div className="field">
              <label className="label">Asignaturas</label>
              <div className="control">
                <SearchField
                  projectId={this.props.projectId}
                  onSelectDemand={this.onSelectDemand}
                  excludeFromResults={this.state.demands}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="columns">
          <div className="column">
            <ResumeTable className="table demand__editor">
              <thead>
                <tr>
                  <th>Carrera</th>
                  <th>Currículo</th>
                  <th>Código</th>
                  <th>Alias</th>
                  <th>Asignatura</th>
                  <th>Lista Cruzada</th>
                  <th>Demanda</th>
                  <th />
                </tr>
              </thead>
              {Object.keys(demands).length ? (
                <tbody>
                  {Object.keys(demands).map((key: string) => {
                    if (demands[key]) {
                      return (
                        <tr key={demands[key].id}>
                          <td>{demands[key].programcode}</td>
                          <td>{demands[key].curriculumcode}</td>
                          <td>{demands[key].course.code}</td>
                          <td>{demands[key].courseLabel}</td>
                          <td>{demands[key].course.code}</td>
                          <td style={{ textAlign: "center" }}>
                            {demands[key].relatedUnits && demands[key].relatedUnits.crosslist ? (
                              <Link
                                to={`/forecast/demand-editor/unit/${
                                  demands[key].relatedUnits.crosslist.id
                                }`}
                                target="_blank"
                              >
                                {demands[key].relatedUnits.crosslist.code}
                              </Link>
                            ) : (
                              <></>
                            )}
                          </td>
                          <td style={{ textAlign: "center" }}>{demands[key].adjusted}</td>
                          <td>
                            <div className="buttons">
                              <a
                                className="button"
                                onClick={e => {
                                  this.onRemoveDemand(demands[key].id)
                                }}
                              >
                                <span className="icon is-small" style={{ paddingTop: "4px" }}>
                                  <Icon size="20" path={mdiTrashCan} />
                                </span>
                              </a>
                            </div>
                          </td>
                        </tr>
                      )
                    } else {
                      return <></>
                    }
                  })}
                  <tr style={{ backgroundColor: "#f1efef" }}>
                    <td colSpan={6}>TOTAL</td>
                    <td style={{ textAlign: "center" }}>{this.updateTotalValue()}</td>
                    <td />
                  </tr>
                </tbody>
              ) : (
                <tbody />
              )}
            </ResumeTable>
          </div>
        </div>

        <div className={showConfirmation ? "modal is-active" : "modal"}>
          <div className="modal-background" />
          <div className="modal-content">
            <div className="box">
              <article className="media">
                <div className="media-content">
                  <div className="content">
                    <p>
                      {countDemandsWithCrossList > 1
                        ? `Con esta acción se cambiarán ${countDemandsWithCrossList} asignaturas desde otras listas cruzadas a la presente.`
                        : `Con esta acción se cambiará ${countDemandsWithCrossList} asignatura desde otra lista cruzada a la presente.`}
                    </p>
                    <div className="columns">
                      <div className="column" style={{ paddingTop: "0px", paddingBottom: "0px" }}>
                        <p className="is-pulled-right">¿Desea continuar?</p>
                      </div>
                    </div>
                    <div className="columns" style={{ marginBottom: "10px" }}>
                      <div className="column" style={{ paddingTop: "0px", paddingBottom: "0px" }}>
                        <div className="buttons is-pulled-right">
                          <div className="buttons is-pulled-right">
                            <a
                              onClick={e => {
                                this.setState({ showConfirmation: false })
                              }}
                              className="button is-link is-outlined"
                            >
                              Cancelar
                            </a>
                            <span
                              className="button is-link"
                              onClick={e => {
                                this.setState({ showConfirmation: false })
                                this.sendForm()
                              }}
                            >
                              Continuar
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </article>
            </div>
          </div>
        </div>

        <div className="columns">
          <div className="column">
            <div className="buttons demand_editor__buttons is-pulled-right">
              <div className="buttons demand_editor__buttons is-pulled-right">
                <Link to="/forecast/demand-editor" className="button is-link is-outlined">
                  Volver
                </Link>
                <span
                  className={this.state.isLoading ? "button is-link is-loading" : "button is-link"}
                  onClick={e => this.handleSubmit(e, this.props.actionType)}
                >
                  {this.props.actionName}
                </span>
              </div>
            </div>
          </div>
        </div>

        <ToastContainer />
      </Fragment>
    )
  }
}

export default withRouter(withApollo(FormCLAdd))
