import React, { ComponentClass, FunctionComponent } from "react"

export type UnitView = {
  levelView: any
  subjectView: any
  crosslistView: any
}

export type SelectItem = {
  label: string | null
  value: string | null
}

export type Dashboard = {
  currentDepartmentList: any
  currentDepartmentListSelected: SelectItem
  currentProgramList: any
  currentProgramListSelected: SelectItem
  selectedTab: string
  unitView: UnitView
  currentUnitTitle: string
  loadingSearch: boolean
}
export interface ForecastContextInterface {
  projectId: string
  dashboard: Dashboard
  updateDashboardCurrentData: Function
  auth?: any
}

const context = React.createContext<ForecastContextInterface | null>(null)

export const ForecastContextProvider = context.Provider
export const ForecastContextConsumer = context.Consumer

type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>

export function withForecastContext<
  P extends { forecastContext?: ForecastContextInterface },
  R = Omit<P, "forecastContext">
  //>(Component: ComponentClass<R> | FunctionComponent<R>): FunctionComponent<R> {
>(Component: any): FunctionComponent<R> {
  return function BoundComponent(props: R) {
    return (
      <ForecastContextConsumer>
        {value => <Component {...props as any} forecastContext={value} />}
      </ForecastContextConsumer>
    )
  }
}
