import React, { Component } from "react"
import { Link } from "react-router-dom"
import styled from "styled-components"

type BreadCrumbItem = {
  label: string
  link: string | null
}

interface IBreadCrumbProps {
  items: BreadCrumbItem[]
}

const BreadCrumbNav = styled.nav`
  & > ul li a {
    color: #4486c3;
  }
`
class BreadCrumb extends Component<IBreadCrumbProps> {
  render() {
    const breadCrumbItems: BreadCrumbItem[] = this.props.items
    return (
      <BreadCrumbNav className="breadcrumb">
        <ul>
          {breadCrumbItems.map((item: BreadCrumbItem, index: number) => (
            <li key={index} className={index === breadCrumbItems.length - 1 ? "is-active" : ""}>
              {item.link ? (
                <Link to={item.link}>{item.label}</Link>
              ) : (
                <a title={item.label}>{item.label}</a>
              )}
            </li>
          ))}
        </ul>
      </BreadCrumbNav>
    )
  }
}

export default BreadCrumb
